import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

// Utility function to check cookie existence
const checkCookie = (cookieName) => {
  const cookies = document.cookie.split("; ").reduce((acc, curr) => {
    const [key, value] = curr.split("=");
    acc[key] = value;
    return acc;
  }, {});
  return cookies[cookieName];
};

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { name, email, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Registration successful!");
        navigate("/sign-in");
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.message || "Failed to register. Please try again."
        );
      }
    } catch (error) {
      toast.error("An error occurred while registering.");
    }
  };

  const handleGoogleSuccess = async (response) => {
    const token = response.credential;

    if (!token) {
      //console.error("Google credential token is missing!");
      toast.error("Failed to get Google credential. Please try again.");
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/google-register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
          credentials: "include", // Include cookies
        }
      );

      if (res.status === 200) {
        const isCookieAccessible = checkCookie("token");
        if (isCookieAccessible) {
          toast.success("Google registration successful!");
          navigate("/home");
        } else {
          //////console.error("Cookie is not accessible!");
          toast.error("Authentication failed. Please try again.");
        }
      } else {
        const data = await res.json();
        toast.error(data.message || "Failed to sign up with Google.");
      }
    } catch (error) {
      //////console.error("Error signing up with Google", error);
      toast.error("An error occurred while signing up with Google.");
    }
  };

  const handleGoogleFailure = (error) => {
    //////console.error("Google Sign-In Error:", error);
    toast.error("Failed to sign up with Google.");
  };

  return (
    <>
      <Helmet>
        <title>Mindlr - Register for AI Workflows</title>
        <meta
          name="description"
          content="Create your Mindlr account to start discovering and customizing AI workflows. Join our community to optimize your productivity."
        />
        <meta
          name="keywords"
          content="Mindlr register, create account, AI tools, blueprint, productivity"
        />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Mindlr - Register for AI Workflows"
        />
        <meta
          property="og:description"
          content="Sign up with Mindlr to access AI toolkits, share insights, and streamline your daily tasks with curated AI solutions."
        />
        <meta
          property="og:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
        <meta property="og:url" content="https://mindlr.ai/register" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mindlr - Register for AI Workflows"
        />
        <meta
          name="twitter:description"
          content="Join Mindlr to discover AI tools, customize workflows, and unlock new productivity levels."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div className="min-h-screen bg-white flex justify-center items-center p-4 md:p-0">
          <NeonGradientCard className="w-full max-w-md p-0 bg-white">
            <form
              onSubmit={handleSubmit}
              className="px-6 py-2 md:py-8 bg-tealcanva6"
            >
              <h2 className="text-4xl font-poppins mb-4 text-center text-black">
                Register
              </h2>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block mb-2 font-poppins text-black"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border border-gray-400 rounded-md bg-white text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block mb-2 font-poppins text-black"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border border-gray-400 rounded-md bg-white text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block mb-2 font-poppins text-black"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPassword(value);
                    setPasswordError(
                      value.length < 6
                        ? "Password must be at least 6 characters long."
                        : ""
                    );
                  }}
                  className={`w-full p-2 border ${
                    passwordError ? "border-red-500" : "border-gray-400"
                  } rounded-md bg-white text-black`}
                  required
                />
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
              </div>

              <div className="flex justify-center">
                <ShimmerButton className="font-poppins mx-auto md:mx-0">
                  Register
                </ShimmerButton>
              </div>
              <div className="my-4 w-full flex justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onFailure={handleGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                  text="continue_with"
                  theme="filled_blue"
                  shape="pill"
                />
              </div>
              <div className="mt-4 text-center">
                <p className="text-blue-500">
                  Already registered? Go to:{" "}
                  <Link to="/sign-in" className="underline">
                    Sign In
                  </Link>
                </p>
              </div>
            </form>
          </NeonGradientCard>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}
