import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PostCard({ post }) {
  const {
    _id,
    uploadedBy,
    textPost,
    videoUrl,
    aiTools = [],
    unverifiedTools = [],
    likes,
    comments,
  } = post || {};

  // Combine verified + unverified tools into one array, sorted by order
  const allTools = [...aiTools, ...unverifiedTools].sort(
    (a, b) => (a.order || 0) - (b.order || 0)
  );

  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  // Local states for like/comment
  const [likesCount, setLikesCount] = useState(likes?.length || 0);
  const [commentsCount, setCommentsCount] = useState(comments?.length || 0);
  const [commentsArr, setCommentsArr] = useState([]);
  const [showCommentsDropdown, setShowCommentsDropdown] = useState(false);
  const [newComment, setNewComment] = useState("");
  const commentDropdownRef = useRef(null);

  // Like logic
  const handleLike = async () => {
    if (!userData?._id) {
      //console.error("User ID not available");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/posts/${_id}/like`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: userData._id }),
        }
      );
      if (response.ok) {
        setLikesCount((prev) => prev + 1);
      } else {
        //console.error("Failed to like the post:", response.statusText);
      }
    } catch (error) {
      //console.error("Error liking the post:", error);
    }
  };

  // Fetch comments
  const fetchComments = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/posts/${_id}/comments`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCommentsArr(data);
        setCommentsCount(data.length);
        setShowCommentsDropdown(true);
      } else {
        //console.error("Failed to fetch comments:", response.statusText);
      }
    } catch (error) {
      //console.error("Error fetching comments:", error);
    }
  };

  // Submit comment
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!userData?._id || !newComment.trim()) {
      //console.error("User ID or comment is missing.");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/posts/${_id}/comment`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: userData._id, comment: newComment }),
        }
      );
      if (response.ok) {
        const newCommentData = await response.json();
        // Optional: fetch user data for newly added comment, if needed
        setCommentsArr((prev) => [newCommentData, ...prev]);
        setCommentsCount((prev) => prev + 1);
        setNewComment("");
        setShowCommentsDropdown(true);
      } else {
        //console.error("Failed to comment on the post:", response.statusText);
      }
    } catch (error) {
      //console.error("Error commenting on the post:", error);
    }
  };

  // Handle profile navigation
  const handleUserClick = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  return (
    <div className="w-full max-w-xl md:max-w-2xl bg-tealcanva11 rounded-lg shadow-md p-6 mb-6 relative">
      {/* Uploader Info */}
      <div className="flex items-center mb-6">
        <Link to={`/uploader-profile/${uploadedBy?._id}`}>
          <img
            src={uploadedBy?.profilePic || "/images/finalplaceholder.png"}
            alt="Profile"
            className="w-12 h-12 rounded-full mr-4 object-cover"
          />
        </Link>
        <div>
          <Link to={`/uploader-profile/${uploadedBy?._id}`}>
            <h2 className="text-xl font-sans text-black">
              {uploadedBy?.name || "Unknown User"}
            </h2>
          </Link>
          <p className="text-sm text-black">{uploadedBy?.bio || ""}</p>
        </div>
      </div>

      {/* Text Post */}
      {textPost && (
        <div className="mb-4 bg-white border border-black p-3 rounded-lg">
          <p className="font-sans text-black text-md">{textPost}</p>
        </div>
      )}

      {/* Video Container */}
      {videoUrl && (
        <div className="mb-4 bg-white border border-black rounded-lg aspect-video">
          <video
            src={videoUrl}
            controls
            className="w-full h-full object-contain"
          />
        </div>
      )}

      {/* Tools Used */}
      {allTools.length > 0 && (
        <div className="bg-white border-4 border-black rounded-lg p-3 mb-4">
          <h3 className="font-sans text-black text-sm font-semibold mb-2 bg-graycanva2 py-1 px-4 inline-block rounded-full">
            Tools Used:
          </h3>

          <div className="flex items-center mt-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
            {allTools.map((tool, index) => {
              const toolKey = tool.toolId
                ? tool.toolId._id
                : `unverified-${index}`;
              const imageUrl = tool.toolId?.Image;
              const link = tool.toolId?.Link;
              const toolName = tool.toolId?.Name || tool.name || "Unknown Tool";

              return (
                <React.Fragment key={toolKey}>
                  {/* Shrinking Tool Icons on Mobile, Keeping Original on Desktop */}
                  <div
                    className="flex-shrink-0 w-5 h-5 md:w-8 md:h-8 rounded-full bg-gray-300 border border-gray-300 overflow-hidden cursor-pointer"
                    onClick={() => {
                      if (link) window.open(link, "_blank");
                    }}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt={toolName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="flex items-center justify-center w-full h-full text-black font-bold text-[6px] md:text-base">
                        {toolName.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>

                  {/* Black Line Between Tools: Adjusted for Mobile & Desktop */}
                  {index !== allTools.length - 1 && (
                    <>
                      <div className="h-px w-2 bg-black mx-1 md:hidden"></div>
                      <div className="h-px w-6 bg-black mx-2 hidden md:block"></div>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}

      {/* Interaction Icons */}
      <div className="flex items-center justify-around mt-4 border-t pt-4">
        <div className="flex items-center">
          <span
            className="material-icons text-gray-600 cursor-pointer"
            onClick={handleLike}
            style={{ fontSize: "24px" }}
          >
            thumb_up
          </span>
          <span className="ml-2 text-white text-sm">{likesCount}</span>
        </div>

        <div className="relative flex items-center">
          <span
            className="material-icons text-gray-600 cursor-pointer"
            onClick={fetchComments}
            style={{ fontSize: "24px" }}
          >
            chat_bubble
          </span>
          <span className="ml-2 text-white text-sm">{commentsCount}</span>
        </div>

        <span
          className="material-icons text-gray-600 cursor-pointer"
          style={{ fontSize: "24px" }}
        >
          share
        </span>
        <span
          className="material-icons text-gray-600 cursor-pointer"
          style={{ fontSize: "24px" }}
        >
          star
        </span>
      </div>

      {/* Comments Dropdown */}
      {showCommentsDropdown && (
        <div
          ref={commentDropdownRef}
          className="absolute bottom-0 left-0 right-0 bg-white rounded-t-lg shadow-xl p-6 z-50"
        >
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            onClick={() => setShowCommentsDropdown(false)}
          >
            <span className="material-icons">close</span>
          </button>
          <h3 className="text-xl font-semibold mb-4">Comments</h3>
          <div
            className="overflow-y-auto space-y-4"
            style={{ maxHeight: commentsArr.length > 3 ? "180px" : "auto" }}
          >
            {commentsArr.length > 0 ? (
              commentsArr.map((comment) => (
                <div key={comment._id} className="flex space-x-3">
                  <img
                    src={
                      comment.user?.profilePic || "/images/finalplaceholder.png"
                    }
                    alt="User"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <p className="text-sm font-semibold text-gray-800">
                      {comment.user?.name || "Unknown User"}
                    </p>
                    <p className="text-sm text-gray-600">{comment.comment}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No comments yet.</p>
            )}
          </div>
          <form className="mt-4 flex" onSubmit={handleCommentSubmit}>
            <input
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Write a comment..."
              className="flex-1 border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              Post
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
