import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet-async";
import DOMPurify from "dompurify";
const showSuccessToast = (message) => {
  toast.success(message, {
    autoClose: 2000,
    hideProgressBar: true,
    pauseOnHover: false,
    toastId: "successToast",
  });
};

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showSuccessToast("Message sent successfully!");
    setSuccessMessage("");
    setErrorMessage("");

    try {
      // Sanitize input before sending
      const sanitizedFormData = {
        name: DOMPurify.sanitize(formData.name),
        email: DOMPurify.sanitize(formData.email),
        message: DOMPurify.sanitize(formData.message),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/contactus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sanitizedFormData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(data.message || "Message sent successfully!");
      } else {
        setErrorMessage(data.message || "Failed to send the message!");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again!");
    }
  };

  return (
    <>
      <Helmet>
        {/* Page Title & Meta Description */}
        <title>Mindlr Platform - How It Works</title>
        <meta
          name="description"
          content="Discover how the Mindlr platform connects you with AI tools, workflow blueprints, and community-driven insights to supercharge your productivity."
        />
        <meta
          name="keywords"
          content="Mindlr, mindlr, platform, AI tools, productivity, blueprint, community"
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Mindlr Platform - How It Works" />
        <meta
          property="og:description"
          content="Learn how Mindlr's platform guides you through AI tool discovery, blueprint creation, and community collaboration for optimized workflows."
        />
        <meta
          property="og:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
        <meta property="og:url" content="https://mindlr.ai/platform" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Mindlr Platform - How It Works" />
        <meta
          name="twitter:description"
          content="Maximize your AI adoption with Mindlr's step-by-step platform. From discovery to execution, see how we streamline your AI workflows."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>

      <div className="min-h-screen bg-tealcanva1 flex items-center justify-center px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl w-full">
          {/* Left Section for Desktop */}
          <div className="hidden md:flex flex-col justify-center space-y-6">
            <h1 className="text-4xl font-bold text-black font-poppins">
              Contact Us
            </h1>
            <p className="text-lg text-black font-poppins leading-relaxed">
              Fill out the form and submit to get in touch with us!
            </p>
          </div>

          {/* Mobile Heading */}
          <div className="md:hidden flex flex-col items-center space-y-4 mb-6">
            <h1 className="text-3xl font-bold text-black font-poppins text-center">
              Contact Us
            </h1>
            <p className="text-base text-black font-poppins text-center leading-relaxed">
              Fill out the form and submit to get in touch with us!
            </p>
          </div>

          {/* Right Section */}
          <div className="bg-white p-6 rounded-lg shadow-md w-full md:w-auto">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-sans font-medium text-black"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Enter your name"
                  className="w-full mt-1 p-2 bg-graycanva2 text-black rounded-2xl shadow-sm focus:ring focus:ring-tealcanva1 font-sans"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-sans font-medium text-black"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Enter your email"
                  className="bg-graycanva2 rounded-2xl w-full mt-1 p-2 text-black shadow-sm focus:ring focus:ring-tealcanva1 font-sans"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-sans font-medium text-black"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Enter your message"
                  className="bg-graycanva2 w-full mt-1 p-2 text-black border rounded-2xl shadow-sm focus:ring focus:ring-tealcanva1 font-sans"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-tealcanva9 text-black font-sans font-bold rounded-2xl hover:bg-teal-600 transition"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
