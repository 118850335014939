import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { FiLoader } from "react-icons/fi";

export default function WelcomePageReplica() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = location.state?.query || sessionStorage.getItem("query") || "";

  const [blueprintData, setBlueprintData] = useState(
    JSON.parse(sessionStorage.getItem("blueprintData")) || null
  );
  const [liveBlueprint, setLiveBlueprint] = useState(
    JSON.parse(sessionStorage.getItem("liveBlueprint")) || null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    if (!query) {
      navigate("/sign-in");
    } else if (!blueprintData || !liveBlueprint) {
      fetchBlueprint(query);
    }
  }, [query]);

  const fetchBlueprint = async (searchQuery) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://app.mindlr.ai/searchblueprint?query=${encodeURIComponent(
          searchQuery
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );

      if (!response.ok) throw new Error("Failed to fetch blueprint");

      const data = await response.json();

      const liveBlueprint = data.steps.map((step) => ({
        step: step.step.replace(/^\((Human Step|Automated Step)\)\s*/i, ""), // Remove "(Human Step)" and "(Automated Step)"
        tool_name: step.tools?.[0]?.tool_name || "N/A",
        tool_link: step.tools?.[0]?.tool_link || "N/A",
        tool_logo: step.tools?.[0]?.tool_logo || "N/A",
        instruction: (
          step.tools?.[0]?.instruction ||
          step.step ||
          "N/A"
        ).replace(/^\((Human Step|Automated Step)\)\s*/i, ""), // Remove "(Human Step)" and "(Automated Step)" from instruction
      }));

      setBlueprintData(data);
      setLiveBlueprint(liveBlueprint);

      // Save in session storage
      sessionStorage.setItem("query", searchQuery);
      sessionStorage.setItem("lastSearchQuery", searchQuery);
      sessionStorage.setItem("blueprintData", JSON.stringify(data));
      sessionStorage.setItem("liveBlueprint", JSON.stringify(liveBlueprint));
    } catch (error) {
      //console.error("Error fetching blueprint:", error);
      setError("Failed to fetch blueprint.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPaymentModal = () => setShowPaymentModal(true);
  const handleClosePaymentModal = () => setShowPaymentModal(false);

  const handleAnyButtonClick = () => {
    navigate("/sign-in");
  };

  return (
    <div className="bg-white min-h-screen p-0">
      {/* Top Bar */}
      <div className="sticky top-0 z-50 bg-white shadow-md">
        <TopBar
          query={query}
          handleSearchClicked={() => fetchBlueprint(query)}
          handleKeyPress={(e) => e.key === "Enter" && fetchBlueprint(query)}
          searchEnabled={true}
          loading={loading} // Show loader in the search bar
          isWelcomePage={true} // Add this to indicate it's the welcome page
        />

        {/* Tab Buttons */}
        <div
          className=" md:ml-0 sticky top-20 z-40 bg-graycanva2 rounded-lg p-0 shadow-md 
    space-x-2 /* smaller gap on mobile */
    md:space-x-12 /* original gap on desktop */"
        >
          <div
            className="flex items-center justify-center mt-0 overflow-x-auto
    space-x-2  /* smaller gap on mobile */
    md:space-x-12 /* original gap on desktop */"
          >
            {/* BLUEPRINTS BUTTON */}
            <button
              className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm  /* mobile defaults */
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md /* original desktop classes */
        rounded-full font-dmsans font-bold transition-all duration-300
        bg-tealcanva6 text-black
      `}
              onClick={handleAnyButtonClick}
            >
              BLUEPRINTS
            </button>

            {/* Divider */}
            <div
              className="
        h-10 w-[2px] bg-gray-400 mx-2   /* smaller height on mobile */
        md:h-10                       /* original height on desktop */
      "
            ></div>

            {/* USE CASES BUTTON */}
            <button
              className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm /* mobile defaults */
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md /* original desktop classes */
        rounded-full font-dmsans font-bold transition-all duration-300
        text-black hover:bg-gray-200
      `}
              onClick={handleAnyButtonClick}
            >
              COMMUNITY
            </button>

            {/* Divider */}
            <div
              className="
        h-10 w-[2px] bg-gray-400 mx-2   /* smaller height on mobile */
        md:h-10                       /* original height on desktop */
      "
            ></div>

            {/* PEOPLE AND TOOLKITS BUTTON */}
            <button
              className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm /* mobile defaults */
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md /* original desktop classes */
        rounded-full font-dmsans font-bold transition-all duration-300
        text-black hover:bg-gray-200
      `}
              onClick={handleAnyButtonClick}
            >
              PEOPLE AND TOOLKITS
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex">
        {/* Left Sidebar */}
        <div
          className="hidden md:flex py-4 mt-1 rounded-lg bg-white w-1/6 flex-col items-center justify-start"
          style={{
            position: "sticky",
            top: "120px",
            alignSelf: "flex-start",
          }}
        >
          <div className="flex flex-col items-center mt-4">
            <div className="flex flex-col items-center mt-4 ml-2">
              <img
                src={"/images/defaultprofpic1.jpg"}
                alt="Profile"
                className="rounded-full w-24 h-24 mb-2 border border-gray-300"
                onClick={handleAnyButtonClick}
              />
              <h3 className="text-md font-sans font-semibold text-black text-center"></h3>
              <p className="text-xs text-gray-500 text-center"> </p>

              {/* Feedback Button */}
              <div className="mt-80 w-full flex justify-center">
                <button
                  className="bg-tealcanva9 hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
                  onClick={handleAnyButtonClick}
                >
                  Feedback
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Blueprint Section */}
        <div className="flex-1 p-4">
          {loading && (
            <div className="flex justify-center items-start pt-4">
              <FiLoader className="animate-spin text-gray-600 text-4xl mt-16 md:mt-0" />
            </div>
          )}
          {error && <p className="text-center text-red-600">{error}</p>}
          {liveBlueprint && (
            <div className="w-full mx-auto rounded-3xl p-4 bg-white border-2 border-tealcanva4">
              {/* Outer Rectangle */}
              <div className="p-4 rounded-3xl bg-none">
                {/* Blueprint Title */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-4 relative">
                  <h2 className="ml-0 md:ml-60 text-md md:text-lg font-sans text-center text-black border-2 border-black rounded-full px-4 py-0 inline-block">
                    {blueprintData?.blueprint_title}
                  </h2>

                  {/* Thumbs Up/Down Buttons & Credit Box for Desktop */}
                  <div className="hidden md:flex mr-0 ml-20 md:ml-0 absolute md:bottom-0 right-0 md:right-4 space-x-4">
                    <button
                      className="text-green-500 text-2xl hover:text-green-400 focus:outline-none"
                      onClick={handleAnyButtonClick}
                    >
                      👍
                    </button>
                    <button
                      className="text-red-500 text-2xl hover:text-red-400 focus:outline-none"
                      onClick={handleAnyButtonClick}
                    >
                      👎
                    </button>
                    <div
                      className="mt-0 md:mt-1 text-black text-xs font-bold px-2 py-1 rounded-full shadow-md cursor-pointer"
                      onClick={handleAnyButtonClick}
                    >
                      10 credits
                    </div>
                  </div>
                </div>

                {/* Thumbs Up/Down Buttons & Credit Box for Mobile */}
                <div className="flex flex-col md:hidden items-center mt-4 space-y-2">
                  <div className="flex space-x-4">
                    <button
                      className="text-green-500 text-2xl hover:text-green-400 focus:outline-none"
                      onClick={handleAnyButtonClick}
                    >
                      👍
                    </button>
                    <button
                      className="text-red-500 text-2xl hover:text-red-400 focus:outline-none"
                      onClick={handleAnyButtonClick}
                    >
                      👎
                    </button>
                  </div>
                  <div
                    className="text-black text-xs font-bold px-2 py-1 rounded-full shadow-md cursor-pointer"
                    onClick={handleAnyButtonClick}
                  >
                    10 credits
                  </div>
                </div>

                {/* Blueprint Steps */}
                <div className="p-4 rounded-3xl shadow-md bg-tealcanva6 relative">
                  {liveBlueprint.map((step, index) => (
                    <div
                      key={index}
                      className="flex items-center mb-6 relative"
                    >
                      {/* Tool Icon */}
                      {step.tool_name !== "N/A" && (
                        <div className="flex flex-col items-center w-16">
                          <a
                            href={step.tool_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-10 h-10 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                          >
                            {step.tool_logo ? (
                              <img
                                src={step.tool_logo}
                                alt={step.tool_name}
                                className="w-full h-full object-contain rounded-full"
                              />
                            ) : (
                              <span className="text-xs font-bold">
                                {step.tool_name[0]}
                              </span>
                            )}
                          </a>
                          <div className="text-xs text-black font-bold font-poppins mt-1 text-center">
                            {step.tool_name}
                          </div>
                        </div>
                      )}

                      {/* Instruction Box */}
                      <div
                        className={`${
                          step.tool_name !== "N/A" ? "ml-4" : ""
                        } flex-1 bg-white p-2 border border-black rounded-none md:rounded-full flex items-center justify-between`}
                      >
                        <p className="text-sm text-black font-montserrat font-semibold">
                          {step.instruction}
                        </p>
                      </div>

                      {/* Tool Switch Icon */}
                      {step.tool_name !== "N/A" && (
                        <div className="relative group">
                          <FaArrowRightArrowLeft
                            className="text-gray-600 ml-2 cursor-pointer"
                            onClick={() => handleAnyButtonClick}
                          />
                          <div
                            className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Click icon to change tool
                          </div>
                        </div>
                      )}

                      {/* Connecting Line */}
                      {index < liveBlueprint.length - 1 && (
                        <div
                          className="absolute left-8 border-l-2 border-2 border-black z-0"
                          style={{
                            top: "calc(100% + 2px)",
                            height: "28px",
                          }}
                        ></div>
                      )}
                    </div>
                  ))}

                  {/* Customize Blueprint Button */}
                  <div className="flex justify-end mt-4">
                    <button
                      className="px-3 py-2 bg-white text-black rounded-full hover:bg-tealcanva2 border border-black font-sans text-md font-semibold"
                      onClick={handleAnyButtonClick}
                    >
                      Customize Blueprint
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Sidebar */}
        <div className="hidden md:flex bg-white opacity-90 w-1/6 min-h-screen p-4 rounded-lg flex-col mt-1 mr-1 mb-1">
          <div></div>
        </div>
      </div>
    </div>
  );
}
