import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckOutForm";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shallowEqual } from "react-redux";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function ToolMakerWelcome() {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(""); // Track user-entered amount
  const [promoCode, setPromoCode] = useState(""); // Track entered promo code
  const [promoSuccess, setPromoSuccess] = useState(false); // Track promo code success
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeStripe = async () => {
      await stripePromise;
      ////console.log("Stripe.js has been successfully loaded.");
      setStripeLoaded(true);
    };

    initializeStripe();
  }, []);

  const handlePayment = async () => {
    if (!paymentAmount || isNaN(paymentAmount) || paymentAmount <= 0) {
      alert("Please enter a valid payment amount.");
      return;
    }

    try {
      setLoading(true);

      const sanitizedAmount = parseFloat(paymentAmount); // Ensure amount is a valid number

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ amount: sanitizedAmount }), // Send sanitized amount
        }
      );

      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else {
        throw new Error("Payment intent creation failed");
      }
    } catch (error) {
      //console.error("Error creating payment intent:", error);
      alert("Error initiating payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePromoCode = async () => {
    try {
      setLoading(true);

      if (
        !promoCode ||
        typeof promoCode !== "string" ||
        promoCode.trim() === ""
      ) {
        alert("Please enter a valid promo code.");
        setLoading(false);
        return;
      }

      const sanitizedPromoCode = promoCode.trim(); // Trim spaces

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/apply-promo-code`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Include user session
          body: JSON.stringify({ promoCode: sanitizedPromoCode }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setPromoSuccess(true);
        showSuccessToast("Promo code applied successfully!");
        window.location.reload(); // Reload the page
        navigate("/home");
      } else {
        alert(data.message || "Failed to apply promo code.");
      }
    } catch (error) {
      //console.error("Error applying promo code:", error);
      alert("Error applying promo code. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const showErrorToast = (message) => {
    toast.error(message, {
      autoClose: 2000, // Close after 2 seconds
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "errorToast",
    });
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      autoClose: 2000,
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "successToast",
    });
  };
  return (
    <div className="md:min-h-screen flex flex-col items-center bg-white md:py-10 md:px-4 mt-0">
      <h2 className="text-4xl font-poppins text-black mb-10">
        Purchase Credits
      </h2>
      <div className="bg-tealcanva5 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h3 className="text-2xl text-white font-poppins mb-6 text-center">
          Payment Details
        </h3>
        <p className="text-white text-center mb-4">1 USD = 5 Credits</p>
        <input
          type="number"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          placeholder="Enter amount in USD"
          className="w-full p-3 text-black bg-white rounded-md border border-gray-300 mb-6"
        />
        <button
          onClick={handlePayment}
          disabled={loading}
          className="bg-green-600 font-poppins text-white px-6 py-3 rounded-full w-full"
        >
          {loading ? "Processing..." : "Proceed to Pay"}
        </button>
        {clientSecret && stripeLoaded && (
          <div className="mt-6">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                onSuccess={() => alert("Payment Successful!")} // Handle success
              />
            </Elements>
          </div>
        )}
      </div>

      {/* Promo Code Section */}
      <div className="bg-tealcanva5 p-6 rounded-lg shadow-lg w-full max-w-md mt-6">
        <h3 className="text-xl text-white font-poppins mb-4 text-center">
          Enter a Promo Code
        </h3>
        <div className="flex items-center bg-white rounded-md border border-gray-300 overflow-hidden">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
            className="flex-grow p-3 text-black bg-white text-sm outline-none"
          />
          <button
            onClick={handlePromoCode}
            disabled={loading}
            className={`px-4 py-3 text-white text-sm font-semibold ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {loading ? "..." : "Apply"}
          </button>
        </div>
      </div>
    </div>
  );
}
