import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import videoReducer from "./features/videos/videoSlice";
import postReducer from "./features/posts/postSlice";
import notificationReducer from "./features/notifications/notificationSlice";
export const store = configureStore({
  reducer: {
    user: userReducer,
    videos: videoReducer,
    posts: postReducer,
    notifications: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true, // Ensure redux-thunk is included
    }),
});
