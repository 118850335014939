// src/components/VideoUploadPopup.js
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCrossCircled } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserVideos } from "../features/videos/videoSlice"; // Adjust path if needed
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";

const VideoUploadPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userStatus = useSelector((state) => state.user.status);

  // Lock body scroll
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // Fetch videos if user status is idle
  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUserVideos());
    }
  }, [userStatus, dispatch]);

  if (userStatus === "loading") console.log("loading");
  if (userStatus === "failed") console.log("Error loading user data");
  if (!userData) console.log("No user data found (not logged in?)");

  // Local states
  const [videoFile, setVideoFile] = useState(null);
  const [textPost, setTextPost] = useState("");
  const [tagLine, setTagLine] = useState("");
  const [uploading, setUploading] = useState(false);

  // AI Tools
  const [selectedAiTools, setSelectedAiTools] = useState([]);
  const [showAiToolDropdown, setShowAiToolDropdown] = useState(false);
  const [aiToolSearchTerm, setAiToolSearchTerm] = useState("");
  const [aiToolSearchResults, setAiToolSearchResults] = useState([]);
  const [aiToolSearching, setAiToolSearching] = useState(false);

  // Add tool modal
  const [showAddToolModal, setShowAddToolModal] = useState(false);
  const [newToolName, setNewToolName] = useState("");
  const [newToolDescription, setNewToolDescription] = useState("");

  // Dropdown ref
  const dropdownRef = useRef(null);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowAiToolDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toasts
  const showErrorToast = (message) =>
    toast.error(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: true,
    });
  const showSuccessToast = (message) =>
    toast.success(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: true,
    });

  // Handle file
  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  // AI tool search
  const handleAiToolSearch = async (e) => {
    const term = e.target.value.trim();
    setAiToolSearchTerm(term);
    if (!term) {
      setAiToolSearchResults([]);
      return;
    }
    setAiToolSearching(true);
    try {
      const encodedTerm = encodeURIComponent(term);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai-tools/search?term=${encodedTerm}`,
        { method: "GET" }
      );
      if (response.ok) {
        const results = await response.json();
        setAiToolSearchResults(results);
      } else {
        //console.error("Failed to search AI tools");
      }
    } catch (error) {
      //console.error("Error searching AI tools:", error);
    } finally {
      setAiToolSearching(false);
    }
  };

  const handleSelectAiTool = (tool) => {
    if (selectedAiTools.find((t) => t._id === tool._id)) return;
    setSelectedAiTools((prev) => [...prev, tool]);
    setAiToolSearchTerm("");
    setAiToolSearchResults([]);
    setShowAiToolDropdown(false);
  };

  const handleRemoveAiTool = (toolId) => {
    setSelectedAiTools((prev) => prev.filter((t) => t._id !== toolId));
  };

  const handleAddCustomAiTool = () => {
    if (!aiToolSearchTerm) return;
    const customTool = {
      _id: `custom-${aiToolSearchTerm}`,
      name: aiToolSearchTerm,
    };
    if (selectedAiTools.find((t) => t._id === customTool._id)) return;
    setSelectedAiTools((prev) => [...prev, customTool]);
    setAiToolSearchTerm("");
    setAiToolSearchResults([]);
    setShowAiToolDropdown(false);
  };

  // Upload
  const handleUpload = async () => {
    if (!videoFile && !textPost.trim()) {
      showErrorToast("Please select a video or enter how you used AI.");
      return;
    }
    setUploading(true);

    const formData = new FormData();
    if (videoFile) formData.append("video", videoFile);
    formData.append("textPost", textPost);
    formData.append("tagLine", tagLine);
    formData.append("aiTools", JSON.stringify(selectedAiTools));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/uploadPost`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );

      if (response.ok) {
        showSuccessToast("Post uploaded successfully!");
        if (userData?._id) {
          dispatch(fetchUserVideos(userData._id));
          window.location.reload();
        }
        onClose();
      } else {
        const errorData = await response.json();
        showErrorToast(errorData.message || "Failed to upload post.");
      }
    } catch (error) {
      console.error("Error uploading post:", error);
      showErrorToast("An error occurred while uploading the post.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Scrollbar styling for popup */}
      <style>
        {`
          /* For WebKit browsers */
          .popup-scroll::-webkit-scrollbar {
            width: 12px;
          }
          .popup-scroll::-webkit-scrollbar-track {
            background: #1F2937; /* Tailwind gray-800 */
          }
          .popup-scroll::-webkit-scrollbar-thumb {
            background-color: #1F2937;
            border-radius: 6px;
          }
          /* For Firefox */
          .popup-scroll {
            scrollbar-width: thin;
            scrollbar-color: #1F2937 #1F2937;
          }
        `}
      </style>
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-white bg-opacity-75 z-50 flex justify-center items-center p-4 md:p-0"
        style={{
          margin: 0,
          overflowY: "auto",
        }}
      >
        {/* Popup container with forced scrollbar from the start */}
        <div
          className="popup-scroll bg-gray-800 md:mt-20 rounded-lg w-full max-w-3xl p-6 relative flex flex-col items-center text-center shadow-lg"
          style={{
            height: "80vh",
            overflowY: "scroll",
          }}
        >
          {/* Close button */}
          <button
            className="absolute top-2 right-1 md:top-4 md:right-0 w-6 h-6 flex items-center justify-center text-white text-2xl"
            onClick={onClose}
          >
            <RxCrossCircled />
          </button>

          {/* Key Guidelines */}
          <div className="relative bg-tealcanva2 text-white p-4 rounded-3xl w-full mb-6 md:mb-2">
            <h3 className="text-lg font-poppins text-black font-bold mb-2">
              Key Guidelines
            </h3>
            <p className="text-black font-poppins text-sm mb-2">
              Please share your genuine use cases of the tools you have used and
              tag them along.
            </p>
            <p className="text-black font-poppins text-xs mb-4">
              All posts and videos will be reviewed by our filtering algorithm
              to ensure compliance with our policies.
            </p>
            <div>
              <Link
                to="/content-policies"
                target="_blank"
                rel="noopener noreferrer"
                className="absolute bottom-2 right-8 text-teal-400 underline text-xs"
              >
                content policies
              </Link>

              <IoIosArrowRoundForward className="text-xl absolute bottom-1 right-4 text-teal-400 transform -rotate-45" />
            </div>
          </div>

          {/* Media Upload */}
          <div className="flex flex-col items-center w-full mb-4 md:mb-2">
            <div className="relative w-full">
              <input
                id="videoFileInput"
                type="file"
                accept="video/*"
                onChange={handleFileChange}
                className="hidden"
              />
              <label
                htmlFor="videoFileInput"
                className="inline-block w-full bg-tealcanva9 text-black p-2 rounded-full cursor-pointer hover:bg-teal-300 transition-colors"
              >
                {videoFile ? videoFile.name : "Add Media"}
              </label>
            </div>
          </div>

          {/* "How have you used AI" */}
          <textarea
            value={textPost}
            onChange={(e) => setTextPost(e.target.value)}
            className="
              bg-graycanva2
              w-full
              p-2
              rounded-lg
              border
              border-gray-300
              text-black
              mb-4
              md:mb-2
              focus:outline-none
              focus:ring-2
              focus:ring-blue-500
              flex-shrink-0
              min-h-[100px]
              resize-vertical
            "
            placeholder="How have you used AI"
          ></textarea>

          {/* AI Tools (4-column grid) */}
          <div className="w-full mb-4" ref={dropdownRef}>
            <label className="block text-white font-semibold mb-2">
              AI Tools
            </label>

            {/* 2 columns on mobile, 4 on desktop */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 p-2 rounded-lg border border-gray-200 shadow-sm bg-gray-50">
              {selectedAiTools.map((tool) => (
                <div
                  key={tool._id}
                  className="flex items-center bg-white rounded-full px-3 py-1"
                >
                  <div className="flex-shrink-0 w-4 h-4 md:w-6 md:h-6 flex items-center justify-center rounded-full bg-tealcanva9 mr-2 overflow-hidden">
                    {tool.Image ? (
                      <img
                        src={tool.Image}
                        alt={tool.Name || tool.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-sm text-black font-semibold">
                        {tool.Name
                          ? tool.Name.charAt(0).toUpperCase()
                          : tool.name
                          ? tool.name.charAt(0).toUpperCase()
                          : "?"}
                      </span>
                    )}
                  </div>
                  <span className="text-black text-sm">
                    {tool.Name || tool.name || "Custom Tool"}
                  </span>
                  <button
                    className="ml-1 text-red-600"
                    onClick={() => handleRemoveAiTool(tool._id)}
                  >
                    <RxCrossCircled />
                  </button>
                </div>
              ))}

              {/* The search input container.
                  Note: It uses "md:relative" so that on mobile (default) it is non‑relative,
                  allowing our absolutely positioned dropdown to cover the full popup container. */}
              <div className="col-span-2 md:col-span-4 md:relative">
                <input
                  type="text"
                  value={aiToolSearchTerm}
                  onChange={handleAiToolSearch}
                  onFocus={() => setShowAiToolDropdown(true)}
                  placeholder="What tools have you used"
                  className="w-full p-3 bg-transparent rounded-md placeholder-gray-500 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                {showAiToolDropdown && (
                  <div className="normal-scroll absolute inset-0 md:inset-auto md:mt-1 md:h-[200px] w-full bg-white shadow-lg rounded-lg p-4 z-20 overflow-y-scroll">
                    <div className="flex justify-end">
                      <button
                        onClick={() => setShowAiToolDropdown(false)}
                        className="text-black text-xs hover:text-gray-700"
                      >
                        ✖
                      </button>
                    </div>

                    {aiToolSearching ? (
                      <p className="text-sm">Searching...</p>
                    ) : aiToolSearchResults.length > 0 ? (
                      aiToolSearchResults.map((tool) => (
                        <div
                          key={tool._id}
                          className="flex items-center cursor-pointer p-1 hover:bg-gray-100 rounded"
                          onClick={() => handleSelectAiTool(tool)}
                        >
                          <div className="flex-shrink-0 w-4 h-4 md:w-6 md:h-6 flex items-center justify-center rounded-full bg-tealcanva9 mr-2 overflow-hidden">
                            {tool.Image ? (
                              <img
                                src={tool.Image}
                                alt={tool.Name}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-sm font-semibold">
                                {tool.Name
                                  ? tool.Name.charAt(0).toUpperCase()
                                  : "?"}
                              </span>
                            )}
                          </div>

                          <span className="text-black text-sm">
                            {tool.Name}
                          </span>
                        </div>
                      ))
                    ) : aiToolSearchTerm ? (
                      // No results but user typed something
                      <div className="flex items-center justify-between">
                        <p className="text-red-500 text-xs">
                          Tool not found. Click + to add
                        </p>
                        <button
                          className="ml-2 text-blue-500"
                          onClick={handleAddCustomAiTool}
                        >
                          <FaPlus />
                        </button>
                      </div>
                    ) : (
                      // If user hasn't typed yet
                      <p className="text-sm text-gray-500">
                        Type to search AI tools...
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Tagline (hidden) */}
          <input
            type="text"
            value={tagLine}
            onChange={(e) => setTagLine(e.target.value)}
            className="bg-graycanva2 text-black w-full p-2 rounded-lg border border-gray-300 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 hidden"
            placeholder="Tagline"
          />

          {/* Post Button */}
          <div className="w-full flex justify-end">
            <button
              onClick={handleUpload}
              className={`bg-tealcanva9 text-black px-6 py-2 rounded-full ${
                uploading ? "opacity-50" : ""
              } transition-colors`}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Post"}
            </button>
          </div>
        </div>
      </div>

      {/* Add a new custom tool modal */}
      {showAddToolModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-[400px]">
            <h3 className="text-lg font-bold mb-4">Add a New Tool</h3>
            <input
              type="text"
              value={newToolName}
              onChange={(e) => setNewToolName(e.target.value)}
              placeholder="Tool Name"
              className="mb-4 px-2 py-1 border border-gray-300 rounded w-full"
            />
            <textarea
              value={newToolDescription}
              onChange={(e) => setNewToolDescription(e.target.value)}
              placeholder="Tool Description"
              className="mb-4 px-2 py-1 border border-gray-300 rounded w-full"
              rows="4"
            />
            <div className="flex justify-end">
              <button
                className="mr-4 px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300 transition-colors"
                onClick={() => setShowAddToolModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                onClick={() => {
                  if (newToolName.trim()) {
                    const customTool = {
                      _id: `custom-${newToolName}`,
                      name: newToolName,
                    };
                    setSelectedAiTools((prev) => [...prev, customTool]);
                    setNewToolName("");
                    setNewToolDescription("");
                    setShowAddToolModal(false);
                    setShowAiToolDropdown(false);
                  }
                }}
              >
                Add Tool
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoUploadPopup;
