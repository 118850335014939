import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const sections = [
  {
    id: "introduction",
    title: "Introduction",
    content: `AI is evolving faster than ever, with new tools and models emerging every day. Yet, for many of us, the abundance of options creates a gap between selection and implementation. At Mindlr, we bridge this gap.

    We make AI tools approachable by helping you visualize their usage, offering step-by-step plans, and providing a space to learn from others. Whether you're looking to enhance your work, simplify personal tasks, or explore the AI wave, Mindlr ensures you achieve practical results, not just theoretical knowledge.`,
  },
  {
    id: "quickstart",
    title: "Quickstart",
    content: `Start your AI journey in 3 simple steps:
    1. Discover: Tell us what you need—describe your task or goal, and we'll recommend tools.
    2. Learn: Use blueprints to follow a clear, actionable step-by-step plan.
    3. Apply: Visualize how others are using AI, upload your results, and build your workflows.

    We’ve designed Mindlr to be intuitive and actionable from day one. No matter your background, AI just got easier!`,
  },
  {
    id: "blueprints",
    title: "Blueprints or Workflows",
    content: `Blueprints are step-by-step processes designed to help you accomplish a task. Each blueprint identifies what needs to be done by you and what can be handled by an AI tool.

    If the recommended tools don’t align with your preferences—whether due to cost, familiarity, or availability—you can easily customize them by clicking the 'Customize' button. Blueprints ensure you have a flexible, actionable plan that combines human creativity with AI-powered efficiency.`,
  },
  {
    id: "integration",
    title: "Integration Playground",
    content: `Imagine a place where you can experiment, learn, and build—all in one. That’s the Integration Playground.

    - Visual Learning: See workflows come to life.
    - Experiment Freely: Combine and test AI tools to fit your needs.
    - Profile Showcase: Share your workflows, tools, and successes with others.

    The Playground helps you go beyond discovery to active implementation. It’s where creativity meets productivity.`,
  },
  {
    id: "faqs",
    title: "FAQs",
    content: [],
  },
];

function scrollWithOffset(element) {
  const yOffset = -120; // Adjust this offset as needed for your sticky header.
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}

export default function PlatformPage() {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("introduction");
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  // In PlatformPage.js (or wherever you define faqItems):

  const faqSections = [
    {
      heading: "General Questions",
      items: [
        {
          question: "1. What is Mindlr?",
          answer: `Mindlr is a platform where users can showcase their tool stack and workflows, discover workflows from the community, and search for workflows by describing their task or situation.`,
        },
        {
          question: "2. Who is Mindlr for?",
          answer: `Mindlr is for anyone looking to integrate AI into their work—whether professionals, students, or businesses—who want to document, share, and discover effective workflows powered by AI tools.`,
        },
        {
          question: "3. How does Mindlr work?",
          answer: `You create a profile, add the tools you use and how you use them, explore workflows from the community, and search for workflows relevant to your tasks.`,
        },
        {
          question: "4. Is Mindlr free to use?",
          answer: `Yes! You can explore and contribute workflows for free. In the future, we may introduce premium features for workflow automation.`,
        },
      ],
    },
    {
      heading: "Using Mindlr",
      items: [
        {
          question: "5. How do I showcase my tool stack on Mindlr?",
          answer: `You can list the AI tools you use, document your workflow, and share it with the community.`,
        },
        {
          question: "6. Can I search for specific workflows?",
          answer: `Yes! You can describe your task or situation, and Mindlr will generate a workflow while also surfacing similar workflows shared by the community.`,
        },
        {
          question: "7. Can I follow other users to see their workflows?",
          answer: `Yes, you can follow AI enthusiasts, professionals, and teams to explore their tool stacks and workflows.`,
        },
        {
          question: "8. Can I modify a workflow I find on Mindlr?",
          answer: `Yes! You can take inspiration from shared workflows and customize them to fit your needs.`,
        },
        {
          question: "9. How do I know if a workflow is effective?",
          answer: `Workflows have community ratings, comments, and engagement metrics to help you evaluate their usefulness.`,
        },
      ],
    },
    {
      heading: "Workflows & Community Features",
      items: [
        {
          question: "10. What is a workflow on Mindlr?",
          answer: `A workflow is a step-by-step process showing how users complete a task using AI tools.`,
        },
        {
          question: "11. Can I share a workflow I created?",
          answer: `Yes! You can document your workflow and share it with the community to help others with similar use cases.`,
        },
        {
          question: "12. Can I collaborate with others on workflows?",
          answer: `Yes! Mindlr allows collaboration, so you can refine and improve workflows together.`,
        },
        {
          question: "13. How can I discover the most relevant workflows?",
          answer: `You can filter by task, industry, tool stack, or community engagement.`,
        },
      ],
    },
    {
      heading: "For AI Tool Creators",
      items: [
        {
          question: "14. I built an AI tool. Can I showcase it on Mindlr?",
          answer: `Yes! You can showcase how your tool fits into real workflows by sharing practical use cases.`,
        },
        {
          question: "15. How can I get my tool featured in relevant workflows?",
          answer: `By demonstrating real use cases and encouraging your users to share workflows that include your tool.`,
        },
        {
          question:
            "16. Can I track how many people engage with workflows featuring my tool?",
          answer: `Yes! Mindlr provides insights into workflow engagement and how often your tool is used.`,
        },
      ],
    },
    {
      heading: "Privacy & Security",
      items: [
        {
          question: "17. Do I need an account to use Mindlr?",
          answer: `No, but creating an account lets you share workflows, save favorites, and engage with the community.`,
        },
        {
          question: "18. What data does Mindlr collect?",
          answer: `We collect only the necessary data to improve recommendations and user experience.`,
        },
        {
          question: "19. Can I delete my account?",
          answer: `Yes, you can delete your account anytime from your settings.`,
        },
      ],
    },
    {
      heading: "Future of Mindlr",
      items: [
        {
          question: "20. What’s next for Mindlr?",
          answer: `We’re working on smarter AI-driven workflow recommendations, deeper integrations, and more community-driven features.`,
        },
        {
          question: "21. Will Mindlr integrate with work apps?",
          answer: `Not yet! However, we plan to partner with different platforms to provide a seamless automation experience in the future.`,
        },
      ],
    },
  ];

  const toggleQuestion = (index) => {
    setExpandedQuestions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  useEffect(() => {
    if (location.state?.section) {
      const targetId = location.state.section;
      const element = document.getElementById(targetId);
      if (element) {
        scrollWithOffset(element);
        setActiveSection(targetId);
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      const visibleSection = sections.find((section) => {
        const element = document.getElementById(section.id);
        if (!element) return false;
        const top = element.offsetTop;
        const bottom = element.offsetTop + element.offsetHeight;
        return scrollPosition >= top && scrollPosition < bottom;
      });

      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const target = document.getElementById(id);
    if (target) {
      scrollWithOffset(target);
      setActiveSection(id);
    }
  };

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>Mindlr Platform - Explore Workflows and AI Tools</title>
        <meta
          name="description"
          content="Learn how the Mindlr platform streamlines AI adoption with step-by-step blueprints, community insights, and an Integration Playground. Start your AI journey today!"
        />
        <meta
          name="keywords"
          content="Mindlr, mindlr, platform, AI workflows, blueprints, community, Integration Playground"
        />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Mindlr Platform - Explore Workflows and AI Tools"
        />
        <meta
          property="og:description"
          content="Discover how Mindlr helps you select AI tools, customize blueprints, and collaborate with a community for successful AI integration."
        />
        <meta property="og:image" content="/images/mindrllogo.png" />
        <meta property="og:url" content="https://mindlr.ai/platform" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mindlr Platform - Explore Workflows and AI Tools"
        />
        <meta
          name="twitter:description"
          content="Select AI tools, follow step-by-step blueprints, and share your solutions on Mindlr's collaborative platform."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>
      <div className="bg-white font-montserrat">
        {/* Header */}
        <header
          className="bg-[#2197bd] text-white py-4 px-6 flex items-center justify-between sticky top-0 z-20"
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          <Link to="/" className="flex items-center">
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-8 mr-2"
            />
            Mindlr
          </Link>

          <Link
            to="/sign-in"
            className="bg-black mr-0 md:mr-16 text-white font-bold flex items-center justify-center px-2 py-1 rounded-full border-2 border-white shadow-md hover:bg-gray-800 transition"
            style={{ fontSize: "1rem" }}
          >
            <span className="mr-2">Sign In</span>
            <div
              className="flex items-center justify-center bg-white text-black rounded-full"
              style={{ width: "24px", height: "24px" }}
            >
              <FaUser size={16} />
            </div>
          </Link>
        </header>

        {/* Content Wrapper */}
        <div className="flex">
          {/* Sidebar - Hidden on Mobile */}
          <nav className="hidden md:block w-1/6 sticky top-[4rem] bg-white p-6 text-black z-10 h-[calc(100vh-4rem)]">
            <h2 className="text-2xl mt-20 mb-4">Platform</h2>
            <ul className="space-y-4">
              {sections.map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => scrollToSection(section.id)}
                    className={`${
                      activeSection === section.id
                        ? "font-bold text-[#2197bd]"
                        : ""
                    } hover:underline text-left`}
                  >
                    {section.title}
                  </button>
                </li>
              ))}
            </ul>
          </nav>

          {/* Divider */}
          <div className="hidden md:block w-[4px] bg-black"></div>

          {/* Main Section */}
          <div className="w-full md:w-3/4 px-8 py-6 mt-20 bg-white text-black">
            {sections.map((section, index) => (
              <section
                key={section.id}
                id={section.id}
                className={`mb-12 ${
                  index === sections.length - 1 ? "pb-96" : ""
                }`}
              >
                <h2 className="text-xl mb-2">{section.title}</h2>
                <hr className="border-t-4 border-gray-800 mb-4 w-[98%]" />
                <p className="text-gray-800 font-poppins whitespace-pre-line">
                  {section.content}
                </p>

                {/* FAQs Section */}
                {section.id === "faqs" && (
                  <div className="space-y-8">
                    {faqSections.map((section, sectionIndex) => (
                      <div key={sectionIndex}>
                        {/* Subheading */}
                        <h2 className="text-xl mb-2">{section.heading}</h2>
                        {/* Line underneath the subheading */}
                        <hr className="border-gray-300 mb-4" />

                        {/* FAQs under the subheading */}
                        <div className="space-y-4">
                          {section.items.map((item, itemIndex) => {
                            const index = `${sectionIndex}-${itemIndex}`;
                            return (
                              <div
                                key={index}
                                className="border-b border-gray-300 pb-4"
                              >
                                <div
                                  className="flex justify-between items-center cursor-pointer"
                                  onClick={() => toggleQuestion(index)}
                                >
                                  <h3 className="text-lg font-bold">
                                    {item.question}
                                  </h3>
                                  <span>
                                    {expandedQuestions.includes(index)
                                      ? "▲"
                                      : "▼"}
                                  </span>
                                </div>
                                {expandedQuestions.includes(index) && (
                                  <p className="mt-2 text-gray-700">
                                    {item.answer}
                                  </p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
