import React from "react";

export default function ContentPolicy() {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-serif p-6 md:p-2">
      <div className="max-w-5xl mx-auto bg-white rounded-xl p-8 md:p-12">
        {/* Page Title */}
        <h1 className="text-4xl font-bold mb-4 text-center text-black">
          Content Policies
        </h1>
        <p className="text-lg text-gray-500 mb-8 text-center">
          Last Updated: <span className="font-medium">2025-02-06</span>
        </p>

        {/* Main Content */}
        <div className="space-y-8 leading-relaxed">
          {/* Introduction */}
          <section>
            <p>
              At <strong>Mindlr</strong>, we encourage creativity and impactful
              storytelling. To ensure the quality and integrity of the content
              shared on our platform, we have established the following content
              policies. By uploading content, you agree to adhere to these
              guidelines:
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              1. Focus on Real-World Applications
            </h2>
            <p>
              All content must focus on demonstrating how AI tools are used in
              different scenarios and achieve specific outcomes. Your post
              should showcase:
            </p>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
              <li>A clear use case or challenge.</li>
              <li>The AI tool in action.</li>
              <li>
                Tangible results or outcomes achieved through the process.
              </li>
            </ul>
            <p className="mt-4">
              Promotional content that endorses or advertises a tool is strictly
              prohibited.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              2. No Explicit Endorsements or Recommendations
            </h2>
            <p>Content should avoid making promotional claims, such as:</p>
            <blockquote className="bg-gray-50 border-l-4 border-gray-400 p-4 italic mt-3">
              <p>
                “This is the best tool for X.” “I recommend everyone use this
                tool.”
              </p>
            </blockquote>
            <p className="mt-4">
              Instead, focus on sharing your experience. For example:
            </p>
            <blockquote className="bg-gray-50 border-l-4 border-gray-400 p-4 italic mt-3">
              <p>“Here’s how I used [Tool Name] to complete [Task].”</p>
              <p>“The outcome I achieved with [Tool Name] was [Result].”</p>
            </blockquote>
            <p className="mt-4">
              <strong>Mindlr</strong> values authentic experiences over opinions
              or endorsements.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Show, Don’t Tell</h2>
            <p>
              Where possible, include visual demonstrations such as screenshots,
              videos, or final outputs to substantiate your claims.
            </p>
            <p className="mt-4">
              Ensure that any visuals shared comply with data privacy standards
              and do not include sensitive or proprietary information.
            </p>
            <p className="mt-4">
              This approach ensures that your content is educational and
              impactful for others in the community.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              4. Structured Content Encouraged
            </h2>
            <p>
              To maintain clarity and quality, we recommend following this
              structure for your posts or videos:
            </p>
            <ul className="list-decimal list-inside ml-4 mt-2 space-y-1">
              <li>
                <strong>Problem:</strong> Describe the challenge or task you
                aimed to solve.
              </li>
              <li>
                <strong>Tool Used:</strong> Specify the AI tool and briefly
                explain why you chose it.
              </li>
              <li>
                <strong>Process:</strong> Demonstrate or describe how you used
                the tool.
              </li>
              <li>
                <strong>Outcome:</strong> Share the results or outputs achieved.
              </li>
            </ul>
            <p className="mt-4">
              <em>
                Note: Following this structure is optional but recommended for
                clarity and maximum impact.
              </em>
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              5. No Sponsored Content
            </h2>
            <p>
              To maintain trust and integrity, sponsored content is not allowed
              on Mindlr. If you have any affiliations with a tool you are
              showcasing, you must disclose this upfront.
            </p>
            <p className="mt-4">
              <strong>Mindlr</strong> reserves the right to remove content that
              appears biased or promotional.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              6. Moderation and Reporting
            </h2>
            <p>
              Mindlr uses a combination of automated systems and human
              moderators to ensure compliance with these policies. Additionally:
            </p>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-2">
              <li>
                Users can report content that violates these policies. Content
                flagged for review may be removed or revised collaboratively
                with the uploader.
              </li>
              <li>
                Repeat violations may result in temporary suspension pending
                further review.
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
