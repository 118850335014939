import React from "react";

export default function ExtensionPrivacyPolicy() {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-serif p-6 md:p-2">
      <div className="max-w-5xl mx-auto bg-white rounded-xl p-8 md:p-12">
        {/* Page Title */}
        <h1 className="text-4xl font-bold mb-4 text-center text-black">
          Privacy Policy for Mindlr Workflow Assistant
        </h1>
        <p className="text-lg text-gray-500 mb-8 text-center">
          Effective Date: <span className="font-medium">March 21, 2025</span> |
          Last Updated: <span className="font-medium">March 21, 2025</span>
        </p>

        {/* Privacy Policy Content */}
        <div className="space-y-8 leading-relaxed">
          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p>
              Mindlr Workflow Assistant ("the Extension") is a Chrome extension
              designed to help users navigate through complex workflows by
              providing AI-powered assistance directly within their browser.
              This Privacy Policy explains how we collect, use, store, and
              protect user data when you use our Extension.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              2. Information We Collect
            </h2>
            <p>
              Based on our examination of the Extension's codebase, we collect
              the following data:
            </p>

            <h3 className="text-xl font-medium mb-3 mt-6">
              2.1. Chat Messages and User Input
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                Text messages that you enter into the chat interface to interact
                with the AI assistant
              </li>
              <li className="text-lg">
                Voice input when using the voice-to-text feature to communicate
                with the assistant
              </li>
              <li className="text-lg">
                These inputs are processed by our AI servers to generate helpful
                responses
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-3 mt-6">2.2. Screenshots</h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                The Extension can capture screenshots of your current browser
                tab when needed to provide contextual assistance
              </li>
              <li className="text-lg">
                Screenshots are processed temporarily to understand the webpage
                context and are not permanently stored
              </li>
              <li className="text-lg">
                The screenshot functionality is implemented through the
                chrome.tabs.captureVisibleTab API
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-3 mt-6">2.3. Webpage Data</h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                The Extension analyzes the content of webpages you visit while
                using it to provide relevant workflow guidance
              </li>
              <li className="text-lg">
                This includes reading DOM elements, text content, form fields,
                and UI components
              </li>
              <li className="text-lg">
                This data is used only for providing immediate assistance and is
                not stored long-term
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-3 mt-6">
              2.4. User Identifiers
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                User IDs to track workflow sessions (sessionId, userId,
                blueprintId, mainId)
              </li>
              <li className="text-lg">
                These identifiers help ensure continuity in your workflow
                experience
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-3 mt-6">
              2.5. Local Storage Data
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                The Extension stores information locally on your device using
                Chrome's storage API, including:
                <ul className="list-disc list-inside ml-8 mt-2">
                  <li>Chat message history (stored in chrome.storage.local)</li>
                  <li>Current workflow state and progress</li>
                  <li>UI preferences (minimized state, chat visibility)</li>
                  <li>Current step index in multi-step workflows</li>
                </ul>
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-3 mt-6">
              2.6. Tab and Window Information
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                The Extension collects information about your browser tabs to
                maintain workflow continuity across multiple pages
              </li>
              <li className="text-lg">
                This includes tab IDs, URLs, and navigation state
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              3. How We Use Your Data
            </h2>
            <p>
              Based on our code analysis, the Mindlr Workflow Assistant uses
              your data for the following purposes:
            </p>
            <ul className="list-disc list-inside space-y-2 mt-4">
              <li className="text-lg">
                <strong>Workflow Guidance:</strong> To provide step-by-step
                assistance through complex workflows
              </li>
              <li className="text-lg">
                <strong>Chat Functionality:</strong> To maintain a conversation
                history between you and the AI assistant
              </li>
              <li className="text-lg">
                <strong>Voice Recognition:</strong> To convert speech to text
                when you use the microphone feature
              </li>
              <li className="text-lg">
                <strong>Visual Context Analysis:</strong> To understand the
                webpage you're viewing and provide relevant help
              </li>
              <li className="text-lg">
                <strong>Multi-Tab Workflow Support:</strong> To maintain your
                workflow state across multiple tabs and page navigations
              </li>
            </ul>
            <p className="mt-6 font-medium">
              According to our codebase, we DO NOT:
            </p>
            <ul className="list-disc list-inside space-y-2 mt-2">
              <li className="text-lg">Sell your data to third parties</li>
              <li className="text-lg">
                Track your entire browsing history (only pages where you
                actively use the Extension)
              </li>
              <li className="text-lg">
                Permanently store screenshots taken by the Extension
              </li>
              <li className="text-lg">
                Collect data from websites beyond what's necessary for workflow
                assistance
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              4. Data Storage & Transmission
            </h2>
            <p>
              Based on our code review, data is stored in the following ways:
            </p>
            <ul className="list-disc list-inside space-y-2 mt-4">
              <li className="text-lg">
                <strong>Local Storage:</strong> Chat history, workflow state,
                and UI preferences are stored locally on your device using
                chrome.storage.local
              </li>
              <li className="text-lg">
                <strong>Server Storage:</strong> Chat messages and workflow data
                are transmitted to Mindlr's servers (app.mindlr.ai) for AI
                processing and to enable continuity between sessions
              </li>
              <li className="text-lg">
                <strong>WebSocket Communication:</strong> The Extension uses
                Socket.io for real-time communication with our servers
              </li>
              <li className="text-lg">
                <strong>Background Script Storage:</strong> The Extension's
                background script temporarily stores workflow state to maintain
                consistency across tabs
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              5. Chrome Extension Permissions & Justifications
            </h2>
            <p>
              Based on our analysis of the manifest.json file, the Extension
              requires these permissions:
            </p>
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Permission
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Purpose & Implementation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      activeTab
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Allows the Extension to analyze the current webpage to
                      provide context-aware assistance. Used in content.js to
                      interact with the page DOM and monitor user activity
                      relevant to the workflow.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      scripting
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Enables the Extension to inject the interactive chat
                      interface and capture screenshots. This permission is used
                      in content.js to create and manage the floating assistant
                      window and in screenshot.js to capture visual context.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      storage
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Used to store chat history, workflow progress, and user
                      preferences locally. Implementation found in background.js
                      and content.js through chrome.storage.local API calls.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      tabs
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Required to maintain workflow continuity across multiple
                      tabs and to enable screenshot functionality. Used in
                      background.js to track tabs and synchronize workflow
                      state.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      Host Permissions (&lt;all_urls&gt;)
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      The Extension requires broad host permissions to function
                      on any website where you might need workflow assistance.
                      Communication is limited to the Mindlr backend
                      (app.mindlr.ai) as seen in the API_BASE_URL constant in
                      content.js.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              6. User Controls & Data Access
            </h2>
            <p>Based on our code analysis, you have the following controls:</p>
            <ul className="list-disc list-inside space-y-2 mt-4">
              <li className="text-lg">
                <strong>Show/Hide Interface:</strong> You can toggle the
                visibility of the chat interface using the Extension's popup
                menu (implemented in popup.js)
              </li>
              <li className="text-lg">
                <strong>Reset Workflow:</strong> You can reset the current
                workflow state at any time (triggerManualReset function in
                content.js)
              </li>
              <li className="text-lg">
                <strong>Clear Storage:</strong> The Extension provides
                functionality to clear stored messages (clear_storage.js)
              </li>
              <li className="text-lg">
                <strong>Voice Input Control:</strong> Voice-to-text
                functionality is opt-in, activated only when you click the
                microphone button (voice-to-text.js)
              </li>
              <li className="text-lg">
                <strong>Data Deletion:</strong> You can contact us to request
                deletion of any server-side data
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 7 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Data Retention</h2>
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Data Type
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Retention Policy
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      Chat Messages
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Stored locally in chrome.storage.local and in Mindlr's
                      servers for workflow continuity. Local data persists until
                      manually cleared using the reset function.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      Screenshots
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Temporarily processed during AI analysis and not
                      permanently stored, as evidenced by the implementation in
                      screenshot.js and background.js.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      Voice Recordings
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Processed in real-time and not stored after conversion to
                      text, as shown in voice-to-text.js implementation.
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 font-medium">
                      Workflow State
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Stored locally in chrome.storage.local and in memory by
                      the background script. Persists until a new workflow is
                      started or manually reset.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 8 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              8. Security Measures
            </h2>
            <p>
              Based on our code review, the Extension implements these security
              practices:
            </p>
            <ul className="list-disc list-inside space-y-2 mt-4">
              <li className="text-lg">
                <strong>Secure Communication:</strong> Data transmitted to
                Mindlr servers uses HTTPS protocol
              </li>
              <li className="text-lg">
                <strong>Content Security Policy:</strong> The manifest.json
                includes a content security policy to protect against XSS
                attacks
              </li>
              <li className="text-lg">
                <strong>Minimal Data Collection:</strong> The Extension only
                collects data relevant to providing workflow assistance
              </li>
              <li className="text-lg">
                <strong>Limited API Access:</strong> Backend communication is
                restricted to Mindlr's own servers
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 9 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              9. Changes to This Privacy Policy
            </h2>
            <p>
              We may update this Privacy Policy as we enhance the Extension with
              new features or change our data practices. Major updates will be
              communicated through the Chrome Web Store and when you update the
              Extension.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 10 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy or would like
              to request deletion of your data, please contact us at:
            </p>
            <address className="mt-3">
              <p>
                📧{" "}
                <a
                  href="mailto:support@mindlr.ai"
                  className="text-blue-600 underline"
                >
                  support@mindlr.ai
                </a>
              </p>
              <p>
                🌐{" "}
                <a
                  href="https://mindlr.ai"
                  className="text-blue-600 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  mindlr.ai
                </a>
              </p>
            </address>
          </section>
        </div>
      </div>
    </div>
  );
}
