import React, { useRef, useState, useEffect } from "react";
import ParticlesAnimation from "../components/ParticlesAnimation";
import ContactForm from "../components/ContactForm";
import ShimmerButton from "../components/ShimmerButton";
import { FaTimes, FaRegPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Import useHistory for navigation
import { GoArrowRight } from "react-icons/go";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import GradualSpacing from "../components/gradual-spacing";
import { Meteors } from "../components/Meteors"; // Adjust to correct path
import FlickeringGrid from "../components/FlickeringGrid";
import { HyperText } from "../components/HyperText";
import WordPullUp from "../components/WordPullUp";
import { MagicCard } from "../components/MagicCardProp";
import BoxReveal from "../components/BoxReveal";
import { cn } from "../lib/utils"; // Adjust based on relative path to utils.js
import Ripple from "../components/Ripple";
import { MarqueeDemo } from "../components/MarqueeDemo";
import { MiniProfileCard } from "../components/MiniProfileCard";
import { useSwipeable } from "react-swipeable";
import { FaSearch } from "react-icons/fa";
import { PiBracketsCurlyLight } from "react-icons/pi";
import { PiBracketsCurlyThin } from "react-icons/pi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { WordRotate } from "../components/WordRotate";
import { Helmet } from "react-helmet-async";

export default function LandingPageVersion2() {
  const contactFormRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoExpanded, setIsVideoExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize useHistory
  const [activeTask, setActiveTask] = useState(0); // To track which task is active
  const [currentProfile, setCurrentProfile] = useState(0);
  const homeTabRef = useRef("blueprints"); // Default tab is "blueprints"
  const [currentTab, setCurrentTab] = useState(homeTabRef.current); // Initialize state from the ref
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [currentView, setCurrentView] = useState(0);
  const [showInitialText, setShowInitialText] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInitialText(false);
    }, 2000); // 2 seconds delay before showing WordRotate

    return () => clearTimeout(timer);
  }, []);

  const blueprintexamples = [
    {
      title:
        "Im writing a thesis and need help to organize my research, format citations, and refine my arguments",
      steps: [
        {
          toolName: "Odin",
          toolLogo: "/images/Odin.png",
          instruction:
            "Use Odin to collect and organize your research data for writing articles or papers.",
        },
        {
          toolName: "CG.AI",
          toolLogo: "/images/CGAI.png",
          instruction:
            "Use CitationGenerator.AI to generate citations in APA, MLA, Chicago, and Harvard styles.",
        },
        {
          toolName: "Human Step",
          instruction: "Review and refine the research outline and arguments.",
        },
        {
          toolName: "Paperpal",
          toolLogo: "/images/Paperpal.png",
          instruction:
            "Use Paperpal as a trusted AI writing assistant that transforms academic writing.",
        },
        {
          toolName: "Human Step",
          instruction:
            "Review and finalize the thesis draft for accuracy and clarity.",
        },
        {
          toolName: "Trinka AI",
          toolLogo: "/images/Trinka.png",
          instruction:
            "Use Trinka AI to correct grammar, spelling errors, and sentence structure.",
        },
      ],
    },
    {
      title:
        "I need AI to create a professional looking resume and portfolio for my UX/UI design job applications",
      steps: [
        {
          toolName: "Human Step",
          instruction:
            "Gather relevant work experience, skills, and education information for the UX/UI design job applications.",
        },
        {
          toolName: "Teal",
          toolLogo: "/images/TealAITool.png",
          instruction:
            "Use Teal to build a professional-looking resume template and track multiple job applications.",
        },
        {
          toolName: "Jobscan",
          toolLogo: "/images/Jobscan.png",
          instruction:
            "Use Jobscan to optimize your resume for ATS and search engines.",
        },
        {
          toolName: "Human Step",
          instruction:
            "Review and edit the generated resume for accuracy and completeness.",
        },
        {
          toolName: "Mobirise",
          toolLogo: "/images/Mobirise.png",
          instruction:
            "Use Mobirise to generate tailored content and layouts for your portfolio.",
        },
        {
          toolName: "Human Step",
          instruction:
            "Review and finalize the portfolio for consistency and quality.",
        },
      ],
    },
    {
      title:
        "I spend too much time on client meetings and design revisions, leaving me with less time to create the garments they request",
      steps: [
        {
          toolName: "Calendly",
          toolLogo: "/images/Calendy Logo.png",
          instruction:
            "Use Calendly to schedule client meetings and send automated reminders.",
        },
        {
          toolName: "Marker.io",
          toolLogo: "/images/Marker.io.png",
          instruction:
            "Use Marker.io to track design revisions and gather client feedback.",
        },
        {
          toolName: "Human Step",
          instruction:
            "Review client feedback and make necessary design revisions.",
        },
        {
          toolName: "Modyfi",
          toolLogo: "/images/Modyfi.svg",
          instruction:
            "Use Modyfi to refine custom designs with non-destructive editing.",
        },
        {
          toolName: "Human Step",
          instruction: "Review and finalize the revised design.",
        },
        {
          toolName: "HubSpot",
          toolLogo: "/images/Hubspot.png",
          instruction:
            "Use HubSpot to track campaign performance and generate summary reports.",
        },
      ],
    },
  ];

  // Example slides data: each “slide” has a user plus an array of tools
  const slides = [
    {
      name: "Sarah Thompson",
      role: "Content Writer",
      photo: "/images/Sarah-Thompson.webp",
      tools: [
        {
          toolName: "Jasper",
          logo: "/images/Jasper.jpg",
          comment:
            "Jasper understands my writing style and generally throws out ideas in my tone.",
        },
        {
          toolName: "Frase",
          logo: "/images/Frase.jpg",
          comment:
            "I use Frase for better visibility of my content; it helps me increase reach effectively.",
        },
        {
          toolName: "Grammarly",
          logo: "/images/Grammarly.svg",
          comment:
            "This is mostly for grammar; GPT does the job too, but Grammarly sticks with me wherever I type.",
        },
      ],
    },
    {
      name: "Rakesh Sharma",
      role: "Medical Professional",
      photo: "/images/Rakesh-Sharma.webp",
      tools: [
        {
          toolName: "Suki AI",
          logo: "/images/Suki AI.jpg", // placeholder logo
          comment:
            "I use Suki AI to automate clinical notes, saving me hours of documentation each week.",
        },
        {
          toolName: "Consensus",
          logo: "/images/Consensus.png",
          comment:
            "I use Consensus to search for latest medical research papers and stay updated.",
        },
        {
          toolName: "Merative",
          logo: "/images/Merative.png",
          comment:
            "I use tools like Micromedex from Merative to gain instant insights from patient data.",
        },
      ],
    },
    {
      name: "Emma Wright",
      role: "Lawyer",
      photo: "/images/Emma-Wright.webp",
      tools: [
        {
          toolName: "Clio Duo",
          logo: "/images/Clio Duo.png",
          comment:
            "I use Clio Duo from Clio Manage to handle legal documents easily.",
        },
        {
          toolName: "Lexis+",
          logo: "/images/Lexis+.jpg",
          comment:
            "Lexis+ really helps me in legal research and quickly find legal resources.",
        },
        {
          toolName: "ChatGPT",
          logo: "/images/ChatGPT.png",
          comment:
            "I use ChatGPT to draft legal documents (e.g. affidavits) quickly and efficiently.",
        },
      ],
    },
    {
      name: "Min-Jun",
      role: "Software Engineer",
      photo: "/images/Min-Jun.webp",
      tools: [
        {
          toolName: "GitHub Copilot",
          logo: "/images/Github Copilot.png",
          comment:
            "We integrated Copilot in our dev process; it helps a lot with quick code generation.",
        },
        {
          toolName: "Cursor",
          logo: "/images/Cursor.jpg",
          comment:
            "I use Cursor as my personal code editor—more AI features than standard VS Code.",
        },
        {
          toolName: "Snyk",
          logo: "/images/Snyk.png",
          comment:
            "Snyk’s AI security analyzer ensures the code I write is free from security issues.",
        },
      ],
    },
  ];

  // Track current slide (0-based)
  const [currentSlide, setCurrentSlide] = useState(0);
  const [blueprintView, setBlueprintView] = useState(0);

  const [isInputFocused, setIsInputFocused] = useState(false);

  // Handle navigating to the next blueprint
  const handleNextBP = () => {
    setBlueprintView((prev) => (prev + 1) % blueprintexamples.length);
  };

  // Handle navigating to the previous blueprint
  const handlePrevBP = () => {
    setBlueprintView((prev) =>
      prev === 0 ? blueprintexamples.length - 1 : prev - 1
    );
  };

  // Handlers for navigation
  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  // Current slide’s data
  const slide = slides[currentSlide];

  const handleNextProfile = () => {
    setCurrentProfile((prev) => (prev + 1) % users.length);
  };

  const handlePrevProfile = () => {
    setCurrentProfile((prev) => (prev === 0 ? users.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setActiveTask(index); // Change active task when clicking dots
  };

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleVideoExpansion = (e) => {
    e.stopPropagation();
    setIsVideoExpanded(!isVideoExpanded);
    if (videoRef.current) {
      if (!isVideoExpanded) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/sign-in");
    }
  };
  const handleNextTask = () => {
    setActiveTask((prevTask) => (prevTask + 1) % tasks.length);
  };

  const handlePrevTask = () => {
    setActiveTask((prevTask) =>
      prevTask === 0 ? tasks.length - 1 : prevTask - 1
    );
  };

  const swipeHandlersForBlueprint = useSwipeable({
    onSwipedLeft: () => handleNextTask(),
    onSwipedRight: () => handlePrevTask(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Enable mouse swipe support for testing on desktop
  });

  const [showFooter, setShowFooter] = useState(true);

  // Hide footer on scroll down; show footer when at top (scrollY = 0)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowFooter(false);
      } else {
        setShowFooter(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      alert("Please enter a query to search.");
      return;
    }

    // Navigate to the replica page and pass the query
    navigate("/welcome", { state: { query: searchQuery.trim() } });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>Mindlr - Discover AI Tools & Workflows</title>
        <meta
          name="description"
          content="Explore how Mindlr helps you leverage AI for improved productivity. Generate workflows, discover top AI tools, and streamline your daily tasks with Mindlr."
        />
        <meta
          name="keywords"
          content="Mindlr, mindlr, AI tools, productivity, workflows, AI integration, task automation"
        />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Mindlr - Discover AI Tools & Workflows"
        />
        <meta
          property="og:description"
          content="Explore how Mindlr helps you leverage AI for improved productivity. Generate workflows, discover top AI tools, and streamline your daily tasks with Mindlr."
        />
        <meta
          property="og:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
        <meta property="og:url" content="https://mindlr.ai/" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mindlr - Discover AI Tools & Workflows"
        />
        <meta
          name="twitter:description"
          content="Explore how Mindlr helps you leverage AI for improved productivity. Generate workflows, discover top AI tools, and streamline your daily tasks with Mindlr."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>
      <div className="relative min-h-screen overflow-hidden bg-tealcanva1">
        {/* Hero Section */}
        <div className="flex flex-col min-h-screen bg-tealcanva1 ml-6 mr-6 mt-1 ">
          <div className="flex flex-col w-full mt-10 md:mt-10">
            <div className="flex justify-center flex-col md:flex-row w-full px-4 md:px-20">
              {/* Left Side */}
              <div className="ml-0 mr-8 md:mr-0 w-full mt-10 md:mt-0 text-black text-center flex flex-col justify-center items-center">
                <h1 className="text-2xl md:text-4xl font-dmsans mt-12 md:mt-32 mb-4 md:mb-0">
                  Build Your AI Portfolio
                </h1>
                <h2 className="text-lg md:text-3xl mt-6 md:mt-2 font-dmsans mb-4 md:mb-0">
                  Show the world how you work with AI.
                </h2>

                {/* Search Bar */}
                <div className="relative mt-8 md:mt-6 w-full px-2 md:px-20">
                  <div className="relative max-w-full sm:max-w-full md:max-w-3xl mx-auto bg-gray-100 w-full py-4 pl-6 pr-16 rounded-full text-black text-center border border-black shadow-md focus-within:ring-2 focus-within:ring-black focus-within:border-black">
                    {/* Mobile View: Static Placeholder */}
                    {!isInputFocused && searchQuery === "" && (
                      <div
                        className="block md:hidden absolute text-center 
                      top-1/2 left-1/2
                      transform -translate-x-1/2 -translate-y-1/2
                      text-sm text-black font-semibold font-sans pointer-events-none"
                      >
                        Describe your task
                      </div>
                    )}

                    {/* Desktop View: Initial Static Text (shown for 2 seconds) */}
                    {!isInputFocused && searchQuery === "" && (
                      <div className="hidden md:flex absolute inset-0 items-center justify-center text-sm md:text-xl text-black font-semibold font-sans pointer-events-none">
                        {showInitialText
                          ? "Describe something you want to work with"
                          : null}
                      </div>
                    )}

                    {/* Desktop View: WordRotate (starts after 2s) */}
                    {!isInputFocused &&
                      searchQuery === "" &&
                      !showInitialText && (
                        <div className="hidden md:block absolute left-6 top-1/2 transform -translate-y-1/2 text-sm md:text-xl text-black font-semibold font-sans overflow-hidden whitespace-nowrap pointer-events-none">
                          <WordRotate />
                        </div>
                      )}

                    {/* Input Field */}
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={() => setIsInputFocused(false)}
                      onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                      className={`bg-transparent w-full text-sm md:text-xl text-black font-sans font-semibold text-center focus:outline-none ${
                        isInputFocused ? "pl-4 text-left" : "pl-28"
                      }`}
                    />

                    {/* Search Icon */}
                    <FaSearch
                      onClick={handleSearch}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700 text-xl md:text-2xl cursor-pointer"
                    />
                  </div>
                </div>

                {/* Now place the MOBILE text & button below the search bar (md:hidden so it doesn't interfere on desktop) */}
                <div className="flex justify-center mt-6 md:mt-4 md:hidden">
                  <span className="italic font-semibold text-lg md:text-xl lg:text-xl">
                    Generate workflows, adapt using AI
                  </span>
                </div>

                <button
                  className="mt-8 md:mt-4 md:hidden px-6 py-3 font-dmsans bg-tealcanva4 text-black rounded-full shadow hover:opacity-90 focus:outline-none font-thin"
                  onClick={() => navigate("/sign-in")}
                >
                  Try Mindlr!
                </button>

                {/* Desktop: Decorative Lines and Text (unchanged) */}
                <div className="justify-center mt-8 hidden md:flex">
                  <span className="italic font-semibold text-xl">
                    Generate workflows, adapt using AI
                  </span>
                </div>

                {/* Desktop: Join Button (unchanged) */}
                <button
                  className="mt-6 hidden md:block px-6 py-3 font-dmsans bg-tealcanva4 text-black rounded-full shadow hover:opacity-90 focus:outline-none font-thin"
                  onClick={() => navigate("/sign-in")}
                >
                  Try Mindlr!
                </button>
              </div>
            </div>

            {/* Footer Section */}
            <div className="hidden md:block w-full text-center mt-20 md:mt-24 lg:mt-24 h-24 relative px-4">
              {/* Footer Content */}
              <div
                className={`
        absolute inset-0 flex flex-col items-center justify-center
        transition-opacity duration-500 ease-in-out
        ${showFooter ? "opacity-100" : "opacity-0"}
      `}
              >
                <div className="flex justify-center space-x-4 md:space-x-6 font-poppins text-gray-600 text-sm">
                  <Link to="/about" className="hover:text-black">
                    About
                  </Link>
                  <Link to="/platform" className="hover:text-black">
                    Platform
                  </Link>
                  <Link
                    to="/platform"
                    state={{ section: "quickstart" }}
                    className="hover:text-black"
                  >
                    Quickstart
                  </Link>
                  <Link to="/blog" className="hover:text-black">
                    Blog
                  </Link>
                  <Link to="/contact-us" className="hover:text-black">
                    Help
                  </Link>
                  <Link to="/terms" className="hover:text-black">
                    Terms
                  </Link>
                </div>
                <div className="text-gray-600 text-xs mt-2 font-poppins">
                  © 2024 Mindlr Inc
                </div>
              </div>
            </div>
          </div>

          {/* Search Bar */}
          {/*<div className="w-auto flex justify-center mt-8 md:mt-4">
          <div className="relative w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate("/sign-in");
                }
              }}
              placeholder="Describe your challenge"
              className=" text-center font-poppins w-full py-3 pr-12 pl-4 text-black bg-gray-800 rounded-full text-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
          </div>
        </div>*/}

          {/* Profiles Slide */}
          <div className="w-full px-3 md:px-16 lg:px-48 mt-40 mb-20">
            <h2 className="text-2xl md:text-3xl font-sans text-black mb-10 px-6 text-center">
              User Profiles: Share tools you use and see what others are up to
            </h2>
            <div className="relative">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                  prevEl: null, // No visible arrows
                  nextEl: null,
                }}
                pagination={{
                  el: ".custom-pagination", // Custom pagination container
                  clickable: true,
                }}
                className="rounded-lg"
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="relative border-2 border-gray-300 rounded-3xl p-6">
                      <div className="flex flex-col md:flex-row items-start">
                        {/* Left Section (Profile Info) */}
                        <div className="flex flex-col items-center justify-center w-full md:w-1/6 text-center">
                          <img
                            src={slide.photo}
                            alt="User Profile"
                            className="w-24 h-24 rounded-full mb-4 border-2 border-gray-300"
                          />
                          <h3 className="text-md text-black font-dmsans font-semibold">
                            {slide.name}
                          </h3>
                          <p className="text-black text-sm font-sans mb-8">
                            {slide.role}
                          </p>
                        </div>
                        {/* Divider Line */}
                        <div
                          className="hidden md:block w-px border-gray-300 border-2 mx-6 self-stretch"
                          style={{
                            borderWidth: "2px",
                          }}
                        />
                        {/* Right Section (Tools and Comments) */}
                        <div className="w-full md:w-3/4 space-y-6">
                          {slide.tools.map((tool, idx) => (
                            <div key={idx}>
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 w-16">
                                  <img
                                    src={tool.logo || "/images/placeholder.png"}
                                    alt="Tool Logo"
                                    className="w-12 h-12 rounded-full"
                                  />
                                  <span className="text-black font-montserrat text-xs font-semibold mt-2 block">
                                    {tool.toolName}
                                  </span>
                                </div>
                                <div className="flex-grow">
                                  <p className="text-black text-md text-lg font-sans bg-graycanva1 p-4 rounded-3xl md:rounded-full">
                                    {tool.comment}
                                  </p>
                                </div>
                              </div>
                              {idx < slide.tools.length - 1 && (
                                <hr
                                  className="border-black my-1 mt-4"
                                  style={{
                                    width: "60%",
                                    marginLeft: "30%",
                                    borderWidth: "1px",
                                  }}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* Custom Pagination Dots */}
              <div
                className="custom-pagination absolute  space-x-2 w-full flex justify-center"
                style={{ bottom: "-10px" }}
              ></div>
            </div>
            <p className="text-center italic text-black text-lg font-itali mt-6">
              Add a tool to your profile and share why you use it.
            </p>
          </div>

          <div className="flex justify-center mt-16">
            <div
              className="w-3/4 border-t border-white"
              style={{ opacity: 0.3 }}
            ></div>
          </div>
          <div>
            <p className="text-black text-center font-sans text-2xl md:text-3xl mt-10 mb-6">
              Blueprints: Discover how, where & with what to leverage AI
            </p>
          </div>
          {/* Gradual Spacing Text */}
          <div className="bg-none font-montserrat w-full">
            <div className="bg-none font-montserrat w-full">
              {/* Outer Div */}
              <div className="w-full flex justify-center items-center mt-6 px-0 md:px-2">
                <div className="relative w-full md:w-2/3 lg:w-1/2 p-3 md:p-4 rounded-3xl shadow-lg border border-gray-300 bg-none">
                  {/* Title */}
                  <div className="w-full text-center mb-4 mt-4">
                    <div className="font-sans border border-gray-300 rounded-full px-3 md:px-4 py-1 md:py-2 text-black text-sm md:text-sm font-normal">
                      {blueprintexamples[blueprintView].title}
                    </div>
                  </div>

                  {/* Steps */}
                  <div className="w-full p-3 md:p-4 rounded-3xl border border-gray-300 bg-none relative">
                    <Swiper
                      modules={[]} // No default pagination
                      spaceBetween={30}
                      slidesPerView={1}
                      onSlideChange={(swiper) =>
                        setBlueprintView(swiper.activeIndex)
                      } // Update blueprintView correctly
                      className="rounded-lg"
                    >
                      {blueprintexamples.map((example, index) => (
                        <SwiperSlide key={index}>
                          <div>
                            {blueprintexamples[blueprintView].steps.map(
                              (step, stepIndex) => (
                                <div
                                  key={stepIndex}
                                  className="relative flex flex-col md:flex-row items-center mb-3 md:mb-4"
                                >
                                  {/* Line Connecting Steps */}
                                  {stepIndex <
                                    blueprintexamples[blueprintView].steps
                                      .length -
                                      1 && (
                                    <div
                                      className="absolute bg-black left-8"
                                      style={{
                                        top: "calc(96% + 3px)",
                                        height: "14px",
                                        width: "1.5px",
                                        zIndex: 1,
                                      }}
                                    ></div>
                                  )}

                                  {/* Tool and Comment Style for Mobile */}
                                  <div className="flex w-full items-center space-x-4 md:hidden">
                                    {/* Tool Logo */}
                                    {step.toolName !== "Human Step" && (
                                      <div className="flex flex-col items-center w-16">
                                        <a
                                          href="#"
                                          className="w-10 h-10 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                        >
                                          {step.toolLogo ? (
                                            <img
                                              src={step.toolLogo}
                                              alt={step.toolName}
                                              className="w-full h-full object-contain rounded-full"
                                            />
                                          ) : (
                                            <span className="text-xs font-bold">
                                              {step.toolName[0]}
                                            </span>
                                          )}
                                        </a>
                                        <div className="text-xs text-black font-bold font-poppins mt-1 text-center">
                                          {step.toolName}
                                        </div>
                                      </div>
                                    )}

                                    {/* Instruction */}
                                    <div
                                      className={`${
                                        step.toolName !== "Human Step"
                                          ? "ml-4"
                                          : ""
                                      } flex-1 bg-white p-2 border border-black rounded-md flex items-center`}
                                    >
                                      <p className="text-xs text-black font-semibold ">
                                        {step.instruction}
                                      </p>
                                    </div>
                                  </div>

                                  {/* Default Style for Desktop */}
                                  <div className="hidden md:flex">
                                    {step.toolName !== "Human Step" && (
                                      <div>
                                        <div
                                          className="flex flex-col items-center mb-1 md:mb-0 md:mr-3 relative z-10"
                                          style={{ minWidth: "50px" }}
                                        >
                                          <img
                                            src={step.toolLogo}
                                            alt={step.toolName}
                                            className="w-8 h-8 md:w-10 ml-4 md:h-10 rounded-full object-cover"
                                          />
                                        </div>
                                        <div>
                                          <p className="text-black font-montserrat font-semibold text-xs ml-1 text-center mt-1">
                                            {step.toolName}
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    <div
                                      className="font-montserrat border border-black px-2 md:px-3 py-1 rounded-full shadow-md bg-white text-xs md:text-sm text-black text-center md:text-left relative z-10"
                                      style={{
                                        marginLeft:
                                          step.toolName !== "Human Step"
                                            ? "10px"
                                            : "0",
                                      }}
                                    >
                                      {step.instruction}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  {/* Brackets */}
                  <div className="hidden md:block absolute top-0 right-40 h-28 w-14 md:w-18">
                    {/* Small Bracket */}
                    <div
                      className="absolute top-2 right-0"
                      style={{ right: "-180px" }}
                    >
                      <img
                        src="/images/Mini right bracket.png"
                        alt="Small Bracket"
                        className="w-2 md:w-4"
                      />
                      <p className="absolute top-[40px] right-[-170px] text-xs md:text-lg font-montserrat text-black">
                        An Example Query
                      </p>
                    </div>

                    {/* Large Bracket */}
                    <div
                      className="absolute top-6 md:top-28 right-0"
                      style={{ right: "-210px" }}
                    >
                      <img
                        src="/images/RIght Bracket.png"
                        alt="Large Bracket"
                        className="h-96"
                      />
                      <p className="absolute top-[50%] right-[-200px] translate-y-[-50%] text-xs md:text-xl font-montserrat text-black">
                        A workflow Blueprint
                      </p>
                    </div>
                  </div>

                  {/* Blue Navigation Dots */}
                  <div className="mt-4 flex justify-center space-x-2">
                    {blueprintexamples.map((_, index) => (
                      <div
                        key={index}
                        onClick={() => setBlueprintView(index)} // Navigate on dot click
                        className={`h-2 w-2 rounded-full cursor-pointer ${
                          index === blueprintView
                            ? "bg-blue-500"
                            : "bg-gray-300"
                        }`}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
      </div>{" "}
    </>
  );
}
