import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import VideoCard from "../components/VideoCard";
import NotificationPopup from "../components/NotificationPopup";
import FeedbackForm from "../components/FeedbackForm";
import ContentLoader from "react-content-loader";
import TopBar from "../components/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../features/user/userSlice";
import { logoutUser } from "../features/user/userSlice";
import { Helmet } from "react-helmet-async";
import PostCard from "../components/PostCard";
import { toast } from "react-toastify";

//import { fetchUserVideos } from "../features/videos/videoSlice";

const UploaderProfile = () => {
  //Redux implemented here :
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (userStatus === "idle") {
      // Means we haven't fetched yet
      dispatch(fetchUserData());
    }
  }, [userStatus, dispatch]);

  if (userStatus === "loading") {
    ////console.log("loading");
  }

  // If there's an error
  if (userStatus === "failed") {
    ////console.log("Error loading user data");
  }

  // If user is not logged in, maybe userData is null
  if (!userData) {
    ////console.log("No user data found (not logged in?)");
  }
  const { uploaderId } = useParams();
  const [uploader, setUploader] = useState(null);
  const [activeTab, setActiveTab] = useState("namekit");
  const [videos, setVideos] = useState([]);
  const [posts, setPosts] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);
  const [userId, setUserId] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [activeSection, setActiveSection] = useState(null); // Tracks followers/following dropdown
  const [userList, setUserList] = useState([]); // Followers/Following list
  const navigate = useNavigate();
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState(null); // Track currently playing video
  const [footerSettingsOpen, setFooterSettingsOpen] = useState(false);
  const footerSettingsRef = useRef(null);
  useEffect(() => {
    const handleFooterClickOutside = (event) => {
      if (
        footerSettingsRef.current &&
        !footerSettingsRef.current.contains(event.target)
      ) {
        setFooterSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleFooterClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleFooterClickOutside);
    };
  }, []);

  const handleVideoPlay = (videoRef) => {
    // Pause the previously playing video
    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoRef) {
      currentlyPlayingVideo.pause();
    }
    // Set the new currently playing video
    setCurrentlyPlayingVideo(videoRef);
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUserId(userData._id);
          checkIfFollowing(userData._id);
        } else {
          ////console.error("Failed to fetch user ID");
        }
      } catch (error) {
        ////console.error("Error fetching user ID:", error);
      }
    };

    const fetchUploaderInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/uploader/${encodeURIComponent(
            uploaderId
          )}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const uploaderData = await response.json();
          setUploader(uploaderData);
          fetchUploaderPosts(uploaderId);
        } else {
          ////console.error("Failed to fetch uploader info");
        }
      } catch (error) {
        ////console.error("Error fetching uploader info:", error);
      }
    };

    const fetchUploaderVideos = async (uploaderId) => {
      try {
        const encodedUploaderId = encodeURIComponent(uploaderId);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/uploader-videos/${encodedUploaderId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const videoData = await response.json();
          setVideos(videoData);
        } else {
          ////console.error("Failed to fetch videos");
        }
      } catch (error) {
        ////console.error("Error fetching videos:", error);
      }
    };

    const fetchUploaderPosts = async (uploaderId) => {
      try {
        const encodedUploaderId = encodeURIComponent(uploaderId);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/uploader-posts/${encodedUploaderId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const postData = await response.json();
          setPosts(postData); // Assume you have a state variable: const [posts, setPosts] = useState([]);
        } else {
          //console.error("Failed to fetch posts");
        }
      } catch (error) {
        //console.error("Error fetching posts:", error);
      }
    };

    const checkIfFollowing = async (loggedInUserId) => {
      try {
        const encodedUploaderId = encodeURIComponent(uploaderId);
        const encodedUserId = encodeURIComponent(loggedInUserId);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/check-follow-status/${encodedUploaderId}?userId=${encodedUserId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setIsFollowing(data.isFollowing); // Set the follow state
        } else {
          //console.error("Failed to fetch follow status");
        }
      } catch (error) {
        //console.error("Error checking follow status:", error);
      }
    };

    fetchUserId();
    fetchUploaderInfo();
  }, [uploaderId]);

  const handleFollow = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/follow/${uploaderId}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      if (response.ok) {
        setIsFollowing(true);
        setUploader((prev) => ({
          ...prev,
          followers: [...prev.followers, userId],
        }));
      } else {
        ////console.error("Failed to follow user:", response.statusText);
      }
    } catch (error) {
      ////console.error("Error following user:", error);
    }
  };

  const handleNotificationClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/mark-read`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        setNotificationCount(0); // Reset notification count to 0
      } else {
        ////console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      ////console.error("Error marking notifications as read:", error);
    }

    setShowNotificationPopup((prev) => !prev); // Toggle the notification popup
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        // Dispatch Redux action to clear user data
        dispatch(logoutUser());

        // Navigate to the sign-in page
        navigate("/sign-in");
      } else {
        ////console.error("Failed to log out");
      }
    } catch (error) {
      ////console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            userId: userData._id, // Send the user ID in the request body
            currentPassword,
            newPassword,
          }),
        }
      );

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        const data = await response.json();
        alert(`Failed to update password: ${data.message}`);
      }
    } catch (error) {
      ////console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const toggleSection = (section) => {
    if (activeSection === section) {
      setActiveSection(null); // Close dropdown
      setUserList([]); // Reset the list
    } else {
      setActiveSection(section); // Open the clicked section
      setUserList(
        section === "followers" ? uploader.followers : uploader.following
      );
    }
  };

  const navigateToUploaderProfile = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const settingsDropdownRef = useRef(null);

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const closeSettingsDropdown = (event) => {
    if (
      settingsDropdownRef.current &&
      !settingsDropdownRef.current.contains(event.target)
    ) {
      setIsSettingsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSettingsDropdown);
    return () => {
      document.removeEventListener("mousedown", closeSettingsDropdown);
    };
  }, []);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const notificationDropdownRef = useRef(null);

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen((prev) => !prev);
  };

  const closeNotificationDropdown = (event) => {
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeNotificationDropdown);
    return () => {
      document.removeEventListener("mousedown", closeNotificationDropdown);
    };
  }, []);
  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/count`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
        //////console.log(notificationCount);
      } else {
        ////console.error("Failed to fetch notification count");
      }
    } catch (error) {
      ////console.error("Error fetching notification count:", error);
    }
  };
  useEffect(() => {
    // Only call if userData and userData._id exist
    if (userData && userData._id) {
      fetchNotificationCount(userData._id);
    }
  }, [userData, fetchNotificationCount]);

  const renderContent = () => {
    switch (activeTab) {
      case "posts":
        return (
          <div>
            <div className="grid grid-cols-1 gap-6 p-1 ml-4 md:ml-0 mt-8 place-items-center">
              {posts.length > 0 ? (
                posts.map((post) => <PostCard key={post._id} post={post} />)
              ) : (
                <p>No posts found for this user.</p>
              )}
            </div>
          </div>
        );

      case "namekit":
        return (
          <div className="ml-4 mt-10 rounded-3xl md:ml-6 mb-20 md:mb-0 bg-tealcanva10 px-4">
            <div className="space-y-8 mt-4">
              {uploader?.toolbox && uploader.toolbox.length > 0 ? (
                uploader.toolbox.map((toolItem, index) => (
                  <div
                    key={toolItem._id.toString()}
                    className="flex items-center space-x-4"
                  >
                    <div className="flex flex-col items-center w-20 md:w-48 space-y-2">
                      <a
                        href={toolItem.tool.Link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={toolItem.tool.Image} // Directly using the image URL from MongoDB
                          alt={toolItem.tool.Name}
                          className="w-12 h-12 mt-6 rounded-full cursor-pointer" // Added cursor-pointer for hover effect
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images/finalplaceholder.png";
                          }}
                        />
                      </a>
                      <span className="mt-2 text-black">
                        {toolItem.tool?.Name || "Tool"}
                      </span>
                    </div>
                    <div
                      className="p-4 mt-4 md:mt-0 text-black bg-gray-300 rounded-lg md:rounded-full w-full"
                      style={{
                        wordBreak: "break-word",
                        lineHeight: "1.5",
                        minHeight: "50px",
                      }}
                    >
                      {toolItem.comment || "No comment provided"}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">
                  No tools found in the toolkit.
                </p>
              )}
            </div>
          </div>
        );

      case "savedPosts":
        return (
          <div className="ml-4 mt-10 rounded-3xl md:ml-6 mb-20 md:mb-0 bg-tealcanva10">
            <h2 className="text-lg font-bold text-center text-gray-700">
              Saved Posts
            </h2>
            <p className="text-center text-gray-500">
              No content yet. Saved posts will appear here.
            </p>
          </div>
        );

      default:
        return null;
    }
  };

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Profile.js

  // 1) The query typed by the user

  // 2) Whether the dropdown is open
  const [searchUsersDropdown, setSearchUsersDropdown] = useState(false);

  // 3) The array of suggestions
  const [searchUsersSuggestions, setSearchUsersSuggestions] = useState([]);

  // 4) The function that fetches suggestions from /api/people/suggestions
  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSearchUsersSuggestions([]);
      setSearchUsersDropdown(false);
      return;
    }
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/people/suggestions?query=${encodeURIComponent(query)}`,
        { method: "GET", credentials: "include" }
      );
      if (response.ok) {
        const data = await response.json();
        setSearchUsersSuggestions(data);
        setSearchUsersDropdown(true);
      }
    } catch (error) {
      ////console.error("Error fetching suggestions:", error);
    }
  };

  // 5) A handler when user clicks a suggestion
  const handleSearchUsersClick = (suggestion) => {
    ////console.log("Selected user:", suggestion);
    // Optionally navigate them somewhere or do something else
    setSearchUsersDropdown(false);
  };

  // 6) A useEffect that triggers fetchSuggestions whenever query changes
  useEffect(() => {
    if (query.trim()) {
      fetchSuggestions(query);
    } else {
      setSearchUsersDropdown(false);
      setSearchUsersSuggestions([]);
    }
  }, [query]);

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>Mindlr - Uploader Profile</title>
        <meta
          name="description"
          content="Discover another Mindlr user's profile and AI toolkit. View their posts, see what AI tools they use, and connect by following them for more inspiring AI solutions."
        />
        <meta
          name="keywords"
          content="Mindlr, mindlr, AI toolkit, user profile, followers, AI posts, connecting"
        />

        {/* Open Graph (Social Media) Tags */}
        <meta property="og:title" content="Mindlr - Uploader Profile" />
        <meta
          property="og:description"
          content="Explore this Mindlr user's AI tools, posts, and follow them for new AI workflow ideas."
        />
        <meta
          property="og:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
        <meta property="og:url" content="https://mindlr.ai/uploader-profile" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Mindlr - Uploader Profile" />
        <meta
          name="twitter:description"
          content="View user-generated AI toolkits and videos on Mindlr. Connect, comment, and stay updated on emerging AI solutions."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>
      <TopBar
        userId={userId || null}
        searchUsersDropdown={searchUsersDropdown}
        setSearchUsersDropdown={setSearchUsersDropdown}
        searchUsersSuggestions={searchUsersSuggestions}
        handleSearchUsersClick={handleSearchUsersClick}
        searchEnabled={true}
        query={query}
        setQuery={setQuery}
        loading={loading} // Pass the loading state
        handleSearchClicked={() => {
          // Handle search clicked if needed; leave empty for Profile page
        }}
        handleKeyPress={(e) => {
          if (e.key === "Enter") {
            // Handle Enter key if needed; leave empty for Profile page
          }
        }}
        showDropdown={showDropdown} // Dropdown state for search suggestions
        nameSuggestions={[]} // Empty as no suggestions are required for Profile
        handleSuggestionClick={() => {}} // Empty as no action is needed for suggestions
        notificationCount={notificationCount} // Pass the notification count
        setNotificationCount={setNotificationCount} // Function to update notification count
        handleNotificationClick={handleNotificationClick} // Notification click handler
        showNotificationPopup={showNotificationPopup} // State for showing notification popup
        setShowNotificationPopup={setShowNotificationPopup} // Function to toggle notification popup
        dropdownOpen={dropdownOpen} // State for profile dropdown
        setDropdownOpen={setDropdownOpen} // Function to toggle profile dropdown
        settingsOpen={settingsOpen} // State for settings dropdown
        setSettingsOpen={setSettingsOpen} // Function to toggle settings dropdown
        handleLogout={handleLogout} // Function to handle logout
        setShowResetPasswordPopup={setShowResetPasswordPopup} // Function to show reset password popup
        setShowLogoutPopup={setShowLogoutPopup} // Function to show logout popup
        // Pass the user ID if available
        showHomeIcon={true} // Add this prop for Profile
        currentPage="profile"
        navigateToHome={true} // Add this prop
        currentTab="toolkits" // Add this prop
      />

      {/*Modile header*/}
      <div className=" md:hidden bg-white w-full p-4 flex flex-col items-start">
        {/* Profile Picture */}
        <div className="relative rounded-full overflow-hidden h-24 w-24 bg-slate-500">
          <img
            src={uploader?.profilePic || "/images/finalplaceholder.png"}
            alt="Profile"
            className="h-full w-full object-cover"
          />
        </div>

        {/* Name and Bio */}
        <div className="mt-4 text-center">
          {!uploader || !uploader.name ? (
            <div>
              <div className="bg-gray-200 h-6 w-3/4 rounded-md mx-auto animate-pulse mb-2"></div>
              <div className="bg-gray-200 h-4 w-2/3 rounded-md mx-auto animate-pulse"></div>
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-poppins text-black">
                {uploader.name}
              </h2>
              <p className="text-sm text-gray-700 font-poppins">
                {uploader.bio || "No bio available."}
              </p>
            </div>
          )}
        </div>

        {/* Follow Button */}
        <div className="mt-1 md:mt-4">
          {userData && uploaderId !== userData._id && (
            <button
              onClick={handleFollow}
              className={`rounded-full w-28 h-10 mt-4 text-white font-poppins text-base ${
                userData.following?.some((user) => user._id === uploaderId)
                  ? "bg-green-500"
                  : "bg-blue-500"
              } flex justify-center items-center`}
              disabled={userData.following?.some(
                (user) => user._id === uploaderId
              )}
            >
              {userData.following?.some((user) => user._id === uploaderId)
                ? "Following"
                : "Follow"}
            </button>
          )}
        </div>

        {/* Followers and Following Section */}
        <div className="flex justify-center space-x-4 mt-4">
          <div
            className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 text-center cursor-pointer"
            onClick={() => toggleSection("followers")}
          >
            <p className="text-black text-md">
              <span className="font-sans">
                {uploader?.followers?.length || 0}
              </span>{" "}
              Followers
            </p>
          </div>
          <div
            className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 text-center cursor-pointer"
            onClick={() => toggleSection("following")}
          >
            <p className="text-black text-md">
              <span className="font-sans">
                {uploader?.following?.length || 0}
              </span>{" "}
              Following
            </p>
          </div>
        </div>

        {/* Followers/Following List */}
        {activeSection && (
          <div className="mt-4 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto w-full">
            <h2 className="text-lg font-bold text-black mb-4 text-center">
              {activeSection === "followers" ? "Followers" : "Following"}
            </h2>

            {/* 1) Check if userList is defined and has items */}
            {userList && userList.length > 0 ? (
              userList.map((viewer) => {
                // 2) Skip if viewer or its _id is missing
                if (!viewer || !viewer._id) return null;

                return (
                  <div
                    key={viewer._id}
                    className="flex items-center space-x-4 cursor-pointer mb-2"
                    onClick={() => navigateToUploaderProfile(viewer._id)}
                  >
                    <img
                      src={viewer.profilePic || "/images/finalplaceholder.png"}
                      alt={viewer.name || "User"}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                    <span className="text-black text-sm">
                      {viewer.name || "Anonymous User"}
                    </span>
                  </div>
                );
              })
            ) : (
              <p className="text-black font-poppins text-sm text-center">
                No users found.
              </p>
            )}
          </div>
        )}
      </div>

      <div className="relative min-h-screen flex pt-0 md:pt-0 bg-white w-full overflow-x-hidden">
        <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
          <FeedbackForm userId={userId} />
        </div>
        <div className="w-[220px] bg-white hidden md:flex flex-col items-center p-4 space-y-6">
          {/* Profile Section */}
          <div className="w-full text-center">
            {/* Profile Picture */}
            <div className="relative mt-14 rounded-full overflow-hidden h-20 w-20 md:h-32 md:w-32 mx-auto bg-slate-500">
              <img
                src={uploader?.profilePic || "/images/finalplaceholder.png"}
                alt="Profile"
                className="h-full w-full object-cover"
              />
            </div>

            {/* Name and Bio */}
            <div>
              {!uploader || !uploader.name ? (
                <div>
                  <div className="bg-gray-200 h-6 w-3/4 rounded-md mx-auto animate-pulse mb-2"></div>
                  <div className="bg-gray-200 h-4 w-2/3 rounded-md mx-auto animate-pulse"></div>
                </div>
              ) : (
                <div>
                  <h2 className="text-xl font-poppins text-black">
                    {uploader.name}
                  </h2>
                  <p className="text-sm text-gray-700 font-poppins">
                    {uploader.bio || "No bio available."}
                  </p>
                </div>
              )}
            </div>

            {/* Follow Button (if viewer is not the uploader) */}
            <div className="mt-4">
              {uploaderId !== userId && (
                <button
                  onClick={handleFollow}
                  className={`rounded-full ml-3 md:ml-9 w-28 h-10 mt-4 md:mt-2 text-white font-poppins text-base ${
                    isFollowing ? "bg-green-500" : "bg-blue-500"
                  } flex justify-center items-center`}
                  disabled={isFollowing}
                >
                  {isFollowing ? "Following" : "Follow"}
                </button>
              )}
            </div>
          </div>

          {/* Followers and Following Section */}
          <div className="w-full">
            <div className="flex flex-col space-y-2">
              {/* Followers Button */}
              <div
                className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 text-center cursor-pointer"
                onClick={() => toggleSection("followers")}
              >
                <p className="text-black text-md">
                  <span className="font-sans">
                    {uploader?.followers?.length || 0}
                  </span>{" "}
                  Followers
                </p>
              </div>
              {/* Following Button */}
              <div
                className="bg-tealcanva6 shadow-md rounded-full px-2 py-2 text-center cursor-pointer"
                onClick={() => toggleSection("following")}
              >
                <p className="text-black text-md">
                  <span className="font-sans">
                    {uploader?.following?.length || 0}
                  </span>{" "}
                  Following
                </p>
              </div>
            </div>

            {/* Render Followers/Following List Immediately Underneath */}
            {activeSection && (
              <div className="mt-2 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto w-full">
                <h2 className="text-lg font-bold text-black mb-4 text-center">
                  {activeSection === "followers" ? "Followers" : "Following"}
                </h2>
                {/* 1) Check if userList is defined and has items */}
                {userList && userList.length > 0 ? (
                  userList.map((viewer) => {
                    // 2) Skip if viewer or viewer._id is missing
                    if (!viewer || !viewer._id) return null;

                    return (
                      <div
                        key={viewer._id}
                        className="flex items-center space-x-4 cursor-pointer mb-2"
                        onClick={() => navigateToUploaderProfile(viewer._id)}
                      >
                        <img
                          src={
                            viewer.profilePic || "/images/finalplaceholder.png"
                          }
                          alt={viewer.name || "User"}
                          className="w-8 h-8 rounded-full object-cover"
                        />
                        <span className="text-black text-sm">
                          {viewer.name || "Anonymous User"}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-black font-poppins text-sm text-center">
                    No users found.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 pr-4">
          <div className="bg-white p-0 md:p-4 mt-1">
            {/* Tabs Section */}
            <div className="mt-10 ml-2 md:ml-8 mr-8 flex justify-between border-b space-x-10">
              <button
                className={`flex-1 px-2 py-2 font-poppins md:text-xl text-black text-center ${
                  activeTab === "namekit"
                    ? "rounded-lg md:rounded-full bg-tealcanva7 font-sans text-black"
                    : ""
                }`}
                onClick={() => setActiveTab("namekit")}
              >
                {uploader?.name || "Uploader"}'s Toolkit
              </button>
              <button
                className={`flex-1 px-2 py-2 font-poppins md:text-xl text-black text-center ${
                  activeTab === "posts"
                    ? "rounded-lg md:rounded-full bg-tealcanva7 font-sans text-black"
                    : ""
                }`}
                onClick={() => setActiveTab("posts")}
              >
                Posts
              </button>
              <button
                className={`flex-1 px-2 py-2 font-poppins md:text-xl text-black text-center ${
                  activeTab === "savedPosts"
                    ? "rounded-lg md:rounded-full bg-tealcanva7 font-sans text-black"
                    : ""
                }`}
                onClick={() => setActiveTab("savedPosts")}
              >
                Saved Posts
              </button>
            </div>

            {renderContent()}
          </div>
        </div>

        {/* Sidebar for Desktop (on the right now) */}
        <div className="hidden md:flex flex-col justify-start items-center bg-white p-4 rounded-lg w-60 h-auto mt-2 mr-4"></div>

        {/* Sticky footer for Mobile View */}
        <div className="fixed bottom-0 left-0 w-screen right-0 bg-tealcanva2 p-4 flex justify-between items-center md:hidden z-50">
          <Link to="/home" className="flex-1 text-center">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              home
            </span>
          </Link>

          <Link to="/profile" className="flex-1 text-center">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              person
            </span>
          </Link>

          <div className="relative flex-1 text-center">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={handleNotificationClick}
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-4px",
                  right: "20%",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "14px",
                }}
              >
                {notificationCount}
              </span>
            )}
            {/* Conditionally render the NotificationPopup */}
            {showNotificationPopup && (
              <NotificationPopup
                userId={userData._id}
                onClose={() => setShowNotificationPopup(false)}
                position="up"
              />
            )}
          </div>

          <div className="relative flex-1 text-center">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={() => setFooterSettingsOpen((prev) => !prev)}
            >
              settings
            </span>

            {footerSettingsOpen && (
              <div
                ref={footerSettingsRef}
                className="absolute right-0 bottom-12 w-48 bg-white rounded-md shadow-lg z-50"
                onClick={(e) => e.stopPropagation()} // Prevent bubbling
              >
                <button
                  onClick={() => setShowResetPasswordPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => handleLogout()}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Logout Confirmation Popup */}
        {showLogoutPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-xl font-bold font-poppins text-black mb-4">
                Are you sure?
              </h2>
              <p className="mb-6">Do you want to log out?</p>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowLogoutPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  No
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-black px-4 py-2 rounded-full"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Reset Password Popup */}
        {showResetPasswordPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold text-black mb-4">
                Reset Password
              </h2>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-bold mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowResetPasswordPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (newPassword.length < 6) {
                      toast.error(
                        "New password must be at least 6 characters long."
                      );
                      return;
                    }
                    handleResetPassword();
                  }}
                  className="bg-blue-500 text-black px-4 py-2 rounded-full"
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploaderProfile;
