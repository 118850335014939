import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

const blogs = [
  {
    title: "Build Your Human Agency",
    category: "Tool Deep Dives",
    link: "/build-your-human-agency",
  },
];

export default function Blog() {
  const [activeCategory, setActiveCategory] = useState("Tool Deep Dives");

  const handleScroll = () => {
    const blogElements = document.querySelectorAll(".blog-item");
    let currentCategory = "Tool Deep Dives";

    blogElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2) {
        currentCategory = element.dataset.category;
      }
    });

    setActiveCategory(currentCategory);
  };

  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>Mindlr Blog - AI Insights and Success Stories</title>
        <meta
          name="description"
          content="Explore the Mindlr Blog for AI tool deep dives, workflow optimizations, industry spotlights, and success stories. Discover how AI can transform your tasks today."
        />
        <meta
          name="keywords"
          content="Mindlr, mindlr, AI blog, AI tools, workflow optimization, success stories"
        />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Mindlr Blog - AI Insights and Success Stories"
        />
        <meta
          property="og:description"
          content="Stay updated on the latest AI trends, tools, and real-world success stories. Discover how Mindlr helps you integrate AI effectively."
        />
        <meta
          property="og:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
        <meta property="og:url" content="https://mindlr.ai/blog" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mindlr Blog - AI Insights and Success Stories"
        />
        <meta
          name="twitter:description"
          content="Discover deep dives on AI tools, optimize workflows, and see how real professionals succeed with Mindlr."
        />
        <meta
          name="twitter:image"
          content="https://mindlr.ai/images/mindrllogo.png"
        />
      </Helmet>
      <div className="bg-tealcanva1 font-montserrat min-h-screen flex">
        {/* Main Content */}
        <main className="w-full lg:w-3/4 mx-auto lg:ml-20 px-6 md:px-16 lg:px-32 py-10">
          <h1 className="text-3xl md:text-5xl font-sans font-semibold text-black mb-4">
            Mindlr’s Blog
          </h1>
          <p className="text-md md:text-lg text-black font-sans">
            We explore the intersection of human creativity and AI capabilities,
            sharing
          </p>
          <p className="text-md md:text-lg underline text-black font-sans cursor-pointer mb-8">
            insights, success stories, and practical guides to help navigate the
            AI landscape.
          </p>

          {/* Blog Items */}
          <div className="space-y-8 md:space-y-12">
            {blogs.map((blog, index) => (
              <a
                key={index}
                href={blog.link}
                className="block bg-tealcanva2 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition blog-item"
                data-category={blog.category}
              >
                <h2 className="text-lg md:text-xl text-black font-sans font-bold">
                  {blog.title}
                </h2>
              </a>
            ))}
          </div>
        </main>

        {/* Sidebar */}
        <aside className="hidden lg:block w-1/5 mt-20 fixed right-0 top-20 bg-tealcanva1 p-6">
          <ul className="space-y-6">
            {[
              "Tool Deep Dives",
              "Workflow Optimizations",
              "Industry Spotlights",
              "Success Stories",
            ].map((category, index) => (
              <li
                key={index}
                className={`cursor-pointer ${
                  activeCategory === category
                    ? "font-bold text-black underline"
                    : "text-black"
                }`}
              >
                {category}
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </>
  );
}
